import React from "react";
import { ProjectManagementStat } from "./stats";

type StatProps = {
  stat: ProjectManagementStat;
  color: string;
};
/* //SSI 0112 - PFERRI - Ajuste no layout do componente referente aos dados do condominio- 14/02/2025 */
const Stat: React.FC<StatProps> = ({ stat, color }) => {
  const IconComponent = stat.icon;

  return (
    <div
      className="d-flex align-items-center flex-nowrap"
      style={{
        boxSizing: "content-box",
        overflow: "hidden",
      }}
    >
      <IconComponent
        width="35"
        height="35"
        className="flex-shrink-0"
        style={{ color, minWidth: "35px" }}
      />
      <div
        className="ms-2"
        style={{
          minWidth: "120px",
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div className="d-flex align-items-end">
          <h3 className="mb-0 me-2">{stat.count}</h3>
          <span className="fs-7 fw-semibold text-body">{stat.title}</span>
        </div>
        <p className="text-body-secondary fs-9 mb-0">{stat.subtitle}</p>
      </div>
    </div>
  );
};

export default Stat;
