//SSI 0096 - MATHEUS BOTELHO - 21/01/25

import React, { useEffect, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import axios from "axios";

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);

interface Assembleia {
  id: number;
  condominio: string;
  id_cond: number;
  dt_assembleia: string;
  tipo_assembleia: string;
  formato_assembleia: string;
}

const GraficoAssembleias: React.FC = () => {
  const [dataPorMes, setDataPorMes] = useState<number[]>([]);
  const [carregando, setCarregando] = useState<boolean>(true);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Função para buscar dados da API
    const fetchAssembleias = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/dados-dashboard/assembleias-por-mes`,
        );
        const assembleias: Assembleia[] = response.data;

        // Processa os dados para agrupar por mês
        const contagemPorMes = Array(12).fill(0); // Inicializa um array com 12 posições (meses)
        assembleias.forEach((assembleia) => {
          const mes = new Date(assembleia.dt_assembleia).getMonth(); // Obtém o índice do mês (0 = Jan, 11 = Dez)
          contagemPorMes[mes]++;
        });

        setDataPorMes(contagemPorMes);
      } catch (error) {
        console.error("Erro ao buscar assembleias:", error);
      } finally {
        setCarregando(false);
      }
    };

    fetchAssembleias();
  }, []);
  /* //SSI 0115 - PFERRI - Alterando as siglas para o português- 18/02/2025 */

  const getOptions = () => ({
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      axisLine: {
        lineStyle: {
          color: "#ccc",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#ccc",
        },
      },
    },
    series: [
      {
        name: "Assembleias",
        type: "line",
        data: dataPorMes,
        smooth: true,
        lineStyle: {
          color: "#5470c6",
        },
      },
    ],
    grid: {
      left: "10%",
      right: "10%",
      bottom: "15%",
      top: "15%",
    },
  });

  if (carregando) {
    return <div>Carregando dados...</div>;
  }

  return (
    <>
      <h3>Assembleias Realizadas</h3>
      <p className="text-body-tertiary mb-0 mb-xl-3">Quantidade por mês</p>
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions()}
        style={{ minHeight: "320px", width: "100%" }}
      />
    </>
  );
};

export default GraficoAssembleias;
