/* //SSI 0141 - PFERRI - Adicionar tabela de condominios dos usuários e melhorias visuais - 27/03/2025 */
import React, { useMemo } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import AdvanceTableProvider from "providers/AdvanceTableProvider";

interface Condominio {
  nome_condominio: string;
  euro_carteiras_id: number;
  colaborador: string;
  reinf_enviado: number;
  leitura_enviado: number;
  consumos_individuais_enviado: number;
  reservas_enviado: number;
  balancetes_enviado: number;
  boleto_enviado: number;
}

interface TabelaUsuarioSimplificadaProps {
  colaborador: string;
  condominios: Condominio[];
}

const TabelaUsuarioCondominios: React.FC<TabelaUsuarioSimplificadaProps> = ({
  colaborador,
  condominios,
}) => {
  const dadosFiltrados = useMemo(() => {
    //SSI 0150 - PFERRI - Correção em fechamento mensal - 01/04/2025
    return condominios
      .filter((c) => c.colaborador.toLowerCase() === colaborador.toLowerCase())
      .sort((a, b) =>
        a.nome_condominio.localeCompare(b.nome_condominio, "pt-BR", {
          sensitivity: "base",
        }),
      );
  }, [condominios, colaborador]);

  const renderCheck = (value: number) => (
    <div style={{ textAlign: "center" }}>
      {value === 1 ? (
        <DoneIcon style={{ color: "#007bff", fontSize: "1.5rem" }} />
      ) : (
        <CloseIcon style={{ color: "#dc3545", fontSize: "1.5rem" }} />
      )}
    </div>
  );

  const columns = useMemo(
    () => [
      {
        id: "nome_condominio",
        accessorKey: "nome_condominio",
        header: "Condomínio",
      },
      {
        id: "reinf_enviado",
        accessorKey: "reinf_enviado",
        header: "ReInf",
        cell: ({ row }: any) => renderCheck(row.original.reinf_enviado),
      },
      {
        id: "balancetes_enviado",
        accessorKey: "balancetes_enviado",
        header: "Balancetes",
        cell: ({ row }: any) => renderCheck(row.original.balancetes_enviado),
      },
      {
        id: "leitura_enviado",
        accessorKey: "leitura_enviado",
        header: "Leitura",
        cell: ({ row }: any) => renderCheck(row.original.leitura_enviado),
      },
      {
        id: "consumos_individuais_enviado",
        accessorKey: "consumos_individuais_enviado",
        header: "Consumos",
        cell: ({ row }: any) =>
          renderCheck(row.original.consumos_individuais_enviado),
      },
      {
        id: "reservas_enviado",
        accessorKey: "reservas_enviado",
        header: "Reservas",
        cell: ({ row }: any) => renderCheck(row.original.reservas_enviado),
      },
      {
        id: "boleto_enviado",
        accessorKey: "boleto_enviado",
        header: "Boletos",
        cell: ({ row }: any) => renderCheck(row.original.boleto_enviado),
      },
    ],
    [colaborador],
  );

  const table = useAdvanceTable({
    data: dadosFiltrados,
    columns,
    pageSize: 5,
    pagination: true,
  });

  return (
    <AdvanceTableProvider {...table} key={`provider-${colaborador}`}>
      <div
        className="card shadow-sm rounded-3 p-3 w-48 mb-3"
        style={{
          height: "480px",
          display: "flex",
          width: "100% ",
          maxWidth: "700px",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowX: "auto",
        }}
      >
        <div>
          <AdvanceTable
            key={`tabela-condominios-${colaborador}`}
            tableProps={{
              className:
                "phoenix-table fs-9 table-hover text-center align-left",
            }}
          />
        </div>
        {dadosFiltrados.length === 0 && (
          <div className="text-center mt-3">Nenhum dado disponível.</div>
        )}
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default TabelaUsuarioCondominios;
