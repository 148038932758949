/* //SSI 0115 - PFERRI - Ajustes no layout e melhorias - 18/02/2025 */
/* //SSI 0141 - PFERRI - Adicionar tabela de condominios dos usuários e melhorias visuais - 27/03/2025 */
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./FechamentoSquadGrafico.module.css";
import ReactSelect from "components/base/ReactSelect";
import { Form } from "react-bootstrap";
import SelectCheckbox from "components/base/SelectCheckbox";
import TabelaUsuarioCondominios from "./TabelaUsuarioCondominios";
import VisaoGeralTable from "../table/VisaoGeralTable";
import TabelaVisaoGeral from "./TabelaVisaoGeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface FechamentoSquadGraficoProps {
  squadId: string | number;
  mes: string | number;
  ano: string | number;
}

interface FechamentoCondominio {
  colaborador: string;
  condominios_abertos: number;
  condominios_fechados: number;
  reinf_enviado: string;
  leitura_enviado: string;
  consumos_individuais_enviado: string;
  reservas_enviado: string;
  balancetes_enviado: string;
  boleto_enviado: string;
  id_func: number;
}

const FechamentoSquadGrafico: React.FC<FechamentoSquadGraficoProps> = ({
  squadId,
  mes,
  ano,
}) => {
  const [dados, setDados] = useState<FechamentoCondominio[]>([]);
  const [totaisSquad, setTotaisSquad] = useState<{ [key: string]: string }>({});
  const [totalAbertos, setTotalAbertos] = useState(0);
  const [totalFechados, setTotalFechados] = useState(0);
  const [graficoSelecionado, setGraficoSelecionado] = useState("total");
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState<FechamentoCondominio | null>(null);
  const [dadosCondominios, setDadosCondominios] = useState<any[]>([]);
  const [condominiosSelecionados, setCondominiosSelecionados] = useState<
    string[]
  >([]);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/financeiro/fechamento-squad/?mes=${mes}&ano=${ano}&squad_id=${squadId}`,
        );
        const data = await response.json();

        setDados(data.fechamento_condominios);
        setTotaisSquad(data.totais_squad);
        setTotalAbertos(data.total_abertos);
        setTotalFechados(data.total_fechados);
        setDadosCondominios(data.condominios);

        // Se houver colaboradores, define o primeiro como selecionado
        if (data.fechamento_condominios.length > 0) {
          setColaboradorSelecionado(data.fechamento_condominios[0]);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      }
    };

    fetchDados();
  }, [mes, ano, squadId]);

  useEffect(() => {
    // Limpa os condomínios selecionados quando trocar de colaborador
    setCondominiosSelecionados([]);
  }, [colaboradorSelecionado]);

  if (!dados.length) return <div>Carregando gráficos...</div>;

  if (dados.length === 0)
    return <div>Nenhum dado disponível para este período.</div>;

  // Ordenação alfabética dos colaboradores, mantendo "Squad Total" por último
  const sortedData = [...dados].sort((a, b) =>
    a.colaborador.localeCompare(b.colaborador),
  );

  // Labels e valores ajustados
  const labels = sortedData
    .map((item) => item.colaborador)
    .concat(["Squad Total"]);
  const fechados = sortedData
    .map((item) => item.condominios_fechados)
    .concat(totalFechados);
  const abertos = sortedData
    .map((item) => item.condominios_abertos)
    .concat(totalAbertos);

  // Define cores: Azul escuro (Fechados), Azul claro (Abertos), Laranja (Squad Total)
  const backgroundColorsFechados = labels.map((label) =>
    label === "Squad Total" ? "rgba(255, 165, 0, 1)" : "#007bff",
  );
  const backgroundColorsAbertos = labels.map((label) =>
    label === "Squad Total"
      ? "rgba(255, 165, 0, 0.3)"
      : "rgba(12, 130, 240, 0.3)",
  );

  // Gráfico de Total de Condomínios
  const totalCondominiosChart = (
    <Bar
      data={{
        labels,
        datasets: [
          {
            label: "Fechados",
            data: fechados,
            backgroundColor: backgroundColorsFechados,
            borderWidth: 1,
            stack: "total",
          },
          {
            label: "Abertos",
            data: abertos,
            backgroundColor: backgroundColorsAbertos,
            borderWidth: 1,
            stack: "total",
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          title: {
            display: true,
            text: `Total de Condomínios Abertos e Fechados`,
          },
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: { beginAtZero: true, stacked: true, grid: { display: false } }, //  Remove linhas verticais
          y: { stacked: true },
        },
      }}
    />
  );

  // Gráfico de métricas
  const metricas = [
    { key: "reinf_enviado", label: "ReInf" },
    { key: "leitura_enviado", label: "Leitura" },
    { key: "consumos_individuais_enviado", label: "Consumos" },
    { key: "reservas_enviado", label: "Reservas" },
    { key: "balancetes_enviado", label: "Balancetes" },
    { key: "boleto_enviado", label: "Boletos" },
  ];

  const metricasChart = (
    <Bar
      data={{
        labels: metricas.map((m) => m.label),
        datasets: [
          {
            label: "Realizados",
            data: metricas.map((m) =>
              parseInt(totaisSquad[m.key]?.split("/")[0] || "0", 10),
            ),
            backgroundColor: "#007bff",
            stack: "metrics",
          },
          {
            label: "Incompletos",
            data: metricas.map((m) => {
              const total = parseInt(
                totaisSquad[m.key]?.split("/")[1] || "0",
                10,
              );
              const realizados = parseInt(
                totaisSquad[m.key]?.split("/")[0] || "0",
                10,
              );
              return total - realizados;
            }),
            backgroundColor: "rgba(12, 130, 240, 0.3)",
            stack: "metrics",
          },
        ],
      }}
      options={{ responsive: true, maintainAspectRatio: false, indexAxis: "y" }}
    />
  );
  // Gráfico de métricas individuais
  const metricasIndividuaisChart = colaboradorSelecionado && (
    <Bar
      data={{
        labels: metricas.map((m) => m.label),
        datasets: [
          {
            label: "Realizados",
            data: metricas.map((m) => {
              const value =
                String((colaboradorSelecionado as any)[m.key])?.split(
                  "/",
                )?.[0] || "0";
              return parseInt(value, 10);
            }),
            backgroundColor: "#007bff",
            stack: "individual",
          },
          {
            label: "Incompletos",
            data: metricas.map((m) => {
              const total =
                String((colaboradorSelecionado as any)[m.key])?.split(
                  "/",
                )?.[1] || "0";
              const realizados =
                String((colaboradorSelecionado as any)[m.key])?.split(
                  "/",
                )?.[0] || "0";
              return parseInt(total, 10) - parseInt(realizados, 10);
            }),
            backgroundColor: "rgba(12, 130, 240, 0.3)",
            stack: "individual",
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y", //  Define a orientação do gráfico para horizontal
        plugins: {
          title: {
            display: true,
            text: `Métricas Individuais - ${colaboradorSelecionado.colaborador}`,
          },
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: { beginAtZero: true, stacked: true, grid: { display: false } }, // Remove linhas verticais
          y: { stacked: true },
        },
      }}
    />
  );

  // Array com as siglas dos meses em português
  const mesesAbreviados = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  // Função para formatar o mês e o ano corretamente
  const formatarMesAno = (mes: string | number, ano: string | number) => {
    const mesNumero = Number(mes); // Converter para número caso seja string
    const mesFormatado = mesesAbreviados[mesNumero - 1] || mes; // Ajustar índice do array
    return `${mesFormatado}/${ano}`;
  };

  return (
    <div className={styles.card}>
      <h2 className={styles.cardTitle}>
        Análise de fechamento dos condomínios - {formatarMesAno(mes, ano)}
      </h2>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => setGraficoSelecionado("total")}
          className={`${styles.button} ${graficoSelecionado === "total" ? styles.active : ""}`}
        >
          Geral
        </button>
        <button
          onClick={() => setGraficoSelecionado("metricas")}
          className={`${styles.button} ${graficoSelecionado === "metricas" ? styles.active : ""}`}
        >
          Por atividade
        </button>
        <button
          onClick={() => setGraficoSelecionado("individual")}
          className={`${styles.button} ${graficoSelecionado === "individual" ? styles.active : ""}`}
        >
          Performance individual
        </button>
        <button
          onClick={() => setGraficoSelecionado("visaoGeral")}
          className={`${styles.button} ${graficoSelecionado === "visaoGeral" ? styles.active : ""}`}
        >
          Resumo
        </button>
      </div>
      {/* SSI 0114 - PFERRI - Adicionar tabela de condominios dos usuários e
      melhorias visuais - 27/03/2025 
      Realizado ajustes no layout e adicionando componentes */}
      {graficoSelecionado === "individual" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100% ",
            gap: "10px",
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label>Selecionar um Colaborador</Form.Label>
            <ReactSelect
              options={dados.map((colab) => ({
                value: colab.colaborador,
                label: colab.colaborador,
              }))}
              placeholder="Selecione um colaborador..."
              value={
                colaboradorSelecionado
                  ? {
                      value: colaboradorSelecionado.colaborador,
                      label: colaboradorSelecionado.colaborador,
                    }
                  : null
              }
              onChange={(newValue) => {
                const option = newValue as {
                  value: string;
                  label: string;
                } | null;

                const colaboradorEncontrado = dados.find(
                  (d) => d.colaborador === option?.value,
                );

                if (
                  colaboradorEncontrado &&
                  colaboradorEncontrado.colaborador !==
                    colaboradorSelecionado?.colaborador
                ) {
                  setColaboradorSelecionado({ ...colaboradorEncontrado }); // força nova referência
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Filtrar Condomínios</Form.Label>
            <SelectCheckbox
              options={dadosCondominios
                .filter(
                  (c) => c.colaborador === colaboradorSelecionado?.colaborador,
                )
                .map((cond) => ({
                  value: cond.nome_condominio,
                  label: cond.nome_condominio,
                }))}
              value={condominiosSelecionados.map((nome) => ({
                value: nome,
                label: nome,
              }))}
              onChange={(newValues) => {
                const selecionados = newValues.map((v) => v.value);
                setCondominiosSelecionados(selecionados);
              }}
              placeholder="Selecione os condomínios..."
            />
          </Form.Group>
        </div>
      )}
      <div className={styles.chartEtableContainer}>
        {graficoSelecionado !== "visaoGeral" && (
          <div
            className="card shadow-sm rounded-3 p-3 w-48 mb-3"
            style={{
              height: "480px",
              display: "flex",
              width: "100% ",
              maxWidth: graficoSelecionado === "individual" ? "700px" : "90%",
              flexDirection: "column",
              justifyContent: "space-between",
              overflowX: "auto",
            }}
          >
            {graficoSelecionado === "total"
              ? totalCondominiosChart
              : graficoSelecionado === "metricas"
                ? metricasChart
                : metricasIndividuaisChart}
          </div>
        )}

        {graficoSelecionado === "individual" && colaboradorSelecionado && (
          <TabelaUsuarioCondominios
            colaborador={colaboradorSelecionado.colaborador}
            condominios={
              condominiosSelecionados.length > 0
                ? dadosCondominios.filter((c) =>
                    condominiosSelecionados.includes(c.nome_condominio),
                  )
                : dadosCondominios
            }
          />
        )}
        {/* //SSI 0150 - PFERRI - Correção em fechamento mensal - implementando a tabela visao geral- 01/04/2025 */}

        {graficoSelecionado === "visaoGeral" && (
          <div
            className="card shadow-sm rounded-3 p-3 w-48 mb-3"
            style={{
              height: "480px",
              display: "flex",
              width: "80% ",
              boxSizing: "border-box",
              maxWidth: "50%vw",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <TabelaVisaoGeral
              key={`visao-geral-${squadId}`}
              mes={Number(mes)}
              ano={Number(ano)}
              colaboradoresPermitidos={dados.map((d) => d.colaborador)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FechamentoSquadGrafico;
