// SSI 0085 - GCOLLE - Informações Usuários, Refazendo página para padrão novo - 10/01/2025
// SSI 0107 - GCOLLE - Adicionar Cadastro Squad - 03/02/2024
// SSI 0119 - PFERRI - Adicionando campo criado_por e alterado_por em tabela euro_usuarios - 07/03/2025
// SSI 0136 - DEBOSSAN - Validação Advogado e Cidade Estado - 24/03/2025
// SSI 0144 - GDIAS - Utilizando tabela euro_cidades_sede para cidade sede - 10/05/2025
import React, {
  useEffect,
  useState,
  FormEvent,
  ChangeEvent,
  FC,
  useContext,
} from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Col, Row, Modal, Form, FloatingLabel } from "react-bootstrap";
import { Edit, Trash2 } from "feather-icons-react";
import AdvanceTable from "components/base/AdvanceTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import ReactSelect from "components/base/ReactSelect";
import UserContext from "components/UserContext";

type TFuncao = "admin" | "Agente" | "Gestor";
type TSituacao = "Ativo" | "Ausente" | "Inativo";
type TSimNao = "Sim" | "Não";

interface IAreaOption {
  value: string;
  label: string;
}

interface ICidadeOption {
  value: string;
  label: string;
  id: number;
  estado: string;
}

interface IUsuario {
  id: number;
  nome: string;
  user: string;
  senha?: string;
  area: string[];
  funcao: TFuncao;
  situacao: TSituacao;
  carteira_ativa: number;
  usuario_cidade: TSimNao;
  advogado_externo: TSimNao;
  email: string;
  cidade_sede: string | string[];
  id_cidade_sede: number | number[] | string;
  telefone: string;
  dt_nascimento: string;
}

interface IFormData {
  nome: string;
  user: string;
  senha: string;
  area: string[];
  funcao: TFuncao;
  situacao: TSituacao;
  carteira_ativa: number;
  usuario_cidade: TSimNao;
  advogado_externo: TSimNao;
  email: string;
  cidade_sede: string[];
  id_cidade_sede: number[];
  telefone: string;
  dt_nascimento: string;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Geral", active: true },
];

const initialFormData: IFormData = {
  nome: "",
  user: "",
  senha: "",
  area: [],
  funcao: "Agente",
  situacao: "Ativo",
  carteira_ativa: 0,
  usuario_cidade: "Não",
  advogado_externo: "Não",
  email: "",
  cidade_sede: [],
  id_cidade_sede: [],
  telefone: "",
  dt_nascimento: "",
};

const Geral: FC = () => {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedUsuario, setSelectedUsuario] = useState<IUsuario | null>(null);
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [validated, setValidated] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [areaOptions, setAreaOptions] = useState<IAreaOption[]>([]);
  const [cidadeOptions, setCidadeOptions] = useState<ICidadeOption[]>([]);
  const [loadingCidades, setLoadingCidades] = useState<boolean>(false);
  const [cidadesError, setCidadesError] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const API_URL = process.env.REACT_APP_API_URL;

  const styles = `
      .action-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .action-buttons .icon-button {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s;
      }
      .phoenix-table tbody tr:hover .icon-button {
        opacity: 1 !important;
        visibility: visible;
      }
    `;

  useEffect(() => {
    fetchUsuarios();
    fetchAreas();
    fetchCidadesSede();
  }, []);

  // Adicionar log quando o modal for aberto para verificar estado atual
  useEffect(() => {
    if (show && isEditing && selectedUsuario) {
      console.log("Modal de edição aberto - Dados do formulário:", formData);
      console.log("Opções de cidades disponíveis:", cidadeOptions);
      console.log(
        "Cidade sede do usuário:",
        formData.cidade_sede,
        "/ ID:",
        formData.id_cidade_sede,
      );

      // Verificar se temos ids das cidades e tentar reparar se necessário
      if (
        formData.cidade_sede.length > 0 &&
        formData.id_cidade_sede.length === 0 &&
        cidadeOptions.length > 0
      ) {
        // Tentar encontrar os IDs correspondentes às cidades
        const cidadesMap = new Map(cidadeOptions.map((c) => [c.value, c.id]));
        const ids = formData.cidade_sede
          .map((cidade) => cidadesMap.get(cidade))
          .filter(Boolean) as number[];

        if (ids.length > 0) {
          console.log("Recuperando IDs de cidades a partir dos nomes:", ids);
          setFormData((prev) => ({ ...prev, id_cidade_sede: ids }));
        }
      }
    }
  }, [show, isEditing, selectedUsuario, cidadeOptions]);

  // Certificar que o valor do ReactSelect seja atualizado quando as opções de cidade forem carregadas
  useEffect(() => {
    if (cidadeOptions.length > 0 && formData.id_cidade_sede.length > 0) {
      console.log(
        "Atualizando seleção de cidades com IDs:",
        formData.id_cidade_sede,
      );
    }
  }, [cidadeOptions, formData.id_cidade_sede]);

  useEffect(() => {
    if (show) {
      console.log(
        "Modal aberto, estado de edição:",
        isEditing ? "Editando" : "Novo",
      );
      console.log("Usuário selecionado:", selectedUsuario);
    }
  }, [show, isEditing, selectedUsuario]);

  const fetchUsuarios = async () => {
    try {
      const response = await axios.get<IUsuario[]>(
        `${API_URL}/usuarios/listar`,
      );
      setUsuarios(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      toast.error("Erro ao buscar usuários");
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get<{ area: string; ativa: boolean }[]>(
        `${API_URL}/usuarios/areas`,
      );
      const options = response.data.map((area) => ({
        value: area.area,
        label: area.area,
      }));
      setAreaOptions(options);
    } catch (error) {
      console.error("Erro ao buscar áreas:", error);
      toast.error("Erro ao buscar áreas");
    }
  };

  const fetchCidadesSede = async () => {
    setLoadingCidades(true);
    setCidadesError("");
    try {
      console.log("Buscando cidades sede...");
      const response = await axios.get<
        { id: number; cidade_sede: string; cidade_sede_estado: string }[]
      >(`${API_URL}/editar-carteira/cidades-sede`);

      console.log("Resposta cidades sede:", response.data);

      if (response && response.data && Array.isArray(response.data)) {
        // Normaliza os dados e remove entradas inválidas
        const options = response.data
          .filter((cidade) => {
            // Certifique-se de que todos os campos necessários existam
            const hasId = cidade && typeof cidade.id === "number";
            const hasCidade =
              cidade &&
              typeof cidade.cidade_sede === "string" &&
              cidade.cidade_sede.trim() !== "";
            const hasEstado =
              cidade && typeof cidade.cidade_sede_estado === "string";
            return hasId && hasCidade;
          })
          .map((cidade) => ({
            value: cidade.cidade_sede.trim(),
            label:
              `${cidade.cidade_sede.trim()} - ${cidade.cidade_sede_estado || ""}`.trim(),
            id: cidade.id,
            estado: cidade.cidade_sede_estado || "",
          }));

        console.log("Cidades sede formatadas:", options);

        // Certifique-se de que haja pelo menos uma opção para evitar problemas
        if (options.length === 0) {
          setCidadesError("Nenhuma cidade sede encontrada no banco de dados");
          return false;
        } else {
          setCidadeOptions(options);
          return true;
        }
      } else {
        console.error("Resposta inválida ao buscar cidades sede:", response);
        setCidadeOptions([]);
        setCidadesError("Formato de resposta inválido ao buscar cidades sede");
        toast.error("Formato de resposta inválido ao buscar cidades sede");
        return false;
      }
    } catch (error) {
      console.error("Erro ao buscar cidades sede:", error);
      setCidadeOptions([]);
      setCidadesError("Erro ao buscar cidades sede");
      toast.error("Erro ao buscar cidades sede");
      return false;
    } finally {
      setLoadingCidades(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setFormData(initialFormData);
    setValidated(false);
  };

  const handleShow = () => {
    setShow(true);
    setIsEditing(false);
    setFormData(initialFormData);
  };

  const handleEditUsuarioClick = (usuario: IUsuario) => {
    // SSI 0136 - DEBOSSAN - 24/03/2025
    // INICIO
    console.log("Editando usuário:", usuario);
    setIsEditing(true);
    setSelectedUsuario(usuario);

    // Verifica se as opções de cidade já foram carregadas
    if (cidadeOptions.length === 0) {
      console.log(
        "Cidades ainda não carregadas, buscando antes de processar usuário",
      );
      fetchCidadesSede().then((success) => {
        if (success) {
          processUsuarioData(usuario);
        } else {
          toast.error(
            "Não foi possível carregar as cidades sede. Tente novamente.",
          );
        }
      });
    } else {
      processUsuarioData(usuario);
    }
    // FIM
  };

  const processUsuarioData = (usuario: IUsuario) => {
    // SSI 0136 - DEBOSSAN - 24/03/2025
    // INICIO
    console.log("Processando usuário para edição:", usuario);
    const userCidadeSede = usuario.id_cidade_sede || usuario.cidade_sede;
    console.log("Processando dados do usuário, cidade sede:", userCidadeSede);

    // Preparando cidade sede para múltipla seleção
    let cidadeSedeData: any[] = [];

    if (Array.isArray(userCidadeSede)) {
      // Já é um array
      console.log("Cidade sede já é um array:", userCidadeSede);
      cidadeSedeData = userCidadeSede;
    } else if (
      typeof userCidadeSede === "string" &&
      userCidadeSede.includes(",")
    ) {
      // É uma string com múltiplos valores separados por vírgula
      console.log("Cidade sede é uma string com vírgulas:", userCidadeSede);
      cidadeSedeData = userCidadeSede.split(",").map((c) => c.trim());
    } else if (userCidadeSede) {
      // É um único valor
      console.log("Cidade sede é um único valor:", userCidadeSede);
      cidadeSedeData = [userCidadeSede];
    }

    console.log("Cidades formatadas:", cidadeSedeData);

    // Convertendo para formato numérico se for string
    let cidadeSedeIds: number[] = [];

    if (cidadeSedeData.length > 0) {
      cidadeSedeIds = cidadeSedeData
        .map((cidade) => {
          if (typeof cidade === "number") {
            return cidade;
          } else if (typeof cidade === "string") {
            // Tenta encontrar o ID pelo nome da cidade
            const cidadeOption = cidadeOptions.find(
              (opt) =>
                opt.value.toLowerCase() === cidade.toLowerCase() ||
                opt.label.toLowerCase().includes(cidade.toLowerCase()),
            );
            if (cidadeOption) {
              console.log(
                `Cidade "${cidade}" encontrada, ID: ${cidadeOption.id}`,
              );
              return cidadeOption.id;
            } else {
              console.warn(
                `Cidade "${cidade}" não encontrada nas opções disponíveis`,
              );
              return null;
            }
          }
          return null;
        })
        .filter(Boolean) as number[];
    }

    console.log("IDs das cidades sede após processamento:", cidadeSedeIds);

    // Preparar o restante dos dados do usuário
    const newFormData = {
      nome: usuario.nome || "",
      user: usuario.user || "",
      senha: "",
      area: Array.isArray(usuario.area) ? usuario.area : [],
      funcao: usuario.funcao || "Agente",
      situacao: usuario.situacao || "Ativo",
      carteira_ativa: usuario.carteira_ativa || 0,
      usuario_cidade: usuario.usuario_cidade || "Não",
      advogado_externo: usuario.advogado_externo || "Não",
      email: usuario.email || "",
      cidade_sede: cidadeSedeData,
      id_cidade_sede: cidadeSedeIds.length > 0 ? cidadeSedeIds : [],
      telefone: usuario.telefone || "",
      dt_nascimento: usuario.dt_nascimento
        ? usuario.dt_nascimento.split("T")[0]
        : "",
    };

    console.log("Dados do formulário configurados:", newFormData);
    setFormData(newFormData);

    // Abre o modal com os dados carregados
    setShow(true);
    // FIM
  };

  const handleSaveUsuario = async (e: FormEvent<HTMLFormElement>) => {
    // SSI 0136 - DEBOSSAN - 24/03/2025
    // INICIO
    e.preventDefault();
    const form = e.currentTarget;

    console.log("Salvando usuário - Dados do formulário:", formData);
    console.log("Estado de edição:", isEditing ? "Editando" : "Criando novo");

    // Validação customizada para alguns campos
    let hasErrors = false;
    const newValidationErrors: { [key: string]: string } = {};

    // Se for nova criação, a senha é obrigatória; na edição, pode ser vazia
    if (!isEditing && !formData.senha) {
      newValidationErrors.senha = "A senha é obrigatória para novos usuários";
      hasErrors = true;
    }

    // Valida cidade sede apenas se usuário_cidade for "Sim"
    if (
      formData.usuario_cidade === "Sim" &&
      formData.id_cidade_sede.length === 0
    ) {
      newValidationErrors.id_cidade_sede =
        "Selecione pelo menos uma cidade sede";
      hasErrors = true;
    }

    // Atualiza os erros de validação
    setValidationErrors(newValidationErrors);

    // Se houver erros de validação, não prossegue
    if (hasErrors) {
      toast.error("Por favor, corrija os erros no formulário.");
      return;
    }

    // Verificação padrão do bootstrap para outros campos
    if (form.checkValidity() === false) {
      e.stopPropagation();
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      setValidated(true);
      return;
    }

    if (isEditing) {
      await handleEditUsuario();
    } else {
      await handleCreateUsuario();
    }
    // FIM
  };
  // SSI 0119 - PFERRI - Passando id no body para salvar quem esta logado e realizando o cadastro - 07/03/2025
  const handleCreateUsuario = async () => {
    if (!userId) {
      toast.error("Erro: Usuário não autenticado.");
      return;
    }
    try {
      const formattedData = {
        ...formData,
        area: formData.area.length > 0 ? formData.area.join(",") : "",
        cidade_sede:
          formData.cidade_sede.length > 0 ? formData.cidade_sede.join(",") : "",
        id_cidade_sede:
          formData.id_cidade_sede.length > 0
            ? formData.id_cidade_sede.join(",")
            : "",
        criado_por: userId,
      };
      await axios.post(`${API_URL}/usuarios/criar`, formattedData);
      await fetchUsuarios();
      handleClose();
      toast.success("Usuário criado com sucesso!");
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.erro || "Erro ao criar usuário");
      } else {
        console.error("Erro ao criar usuário:", error);
        toast.error("Erro ao criar usuário");
      }
    }
  };

  const handleEditUsuario = async () => {
    if (!selectedUsuario) {
      toast.error("Nenhum usuário selecionado para editar.");
      return;
    }

    try {
      // Cria um objeto com os dados formatados
      let formattedData: any = {
        ...formData,
        id: selectedUsuario.id, // Garantir que o ID do usuário seja enviado
        area: Array.isArray(formData.area) ? formData.area.join(",") : "",
        cidade_sede: Array.isArray(formData.cidade_sede)
          ? formData.cidade_sede.join(",")
          : "",
        id_cidade_sede: Array.isArray(formData.id_cidade_sede)
          ? formData.id_cidade_sede.join(",")
          : "",
        alterado_por: userId,
      };

      // Remove a senha se estiver vazia para não sobrescrever a senha existente
      if (!formData.senha) {
        delete formattedData.senha;
      }

      console.log("Dados formatados para edição:", formattedData);

      // Envia a requisição PUT
      const response = await axios.put(
        `${API_URL}/usuarios/editar/${selectedUsuario.id}`,
        formattedData,
      );

      // Verifica a resposta do servidor
      if (response.status === 200) {
        await fetchUsuarios();
        handleClose();
        toast.success(
          response.data.message || "Usuário atualizado com sucesso!",
        );
      } else {
        toast.error(response.data.erro || "Erro ao atualizar usuário.");
      }
    } catch (error: any) {
      console.error("Erro ao atualizar usuário:", error);

      // Trata mensagens específicas do backend ou um erro genérico
      if (error.response && error.response.data && error.response.data.erro) {
        toast.error(error.response.data.erro);
      } else {
        toast.error("Erro ao atualizar usuário. Tente novamente.");
      }
    }
  };

  const handleDeleteUsuario = async (id: number) => {
    if (!id) {
      toast.error("ID inválido para exclusão");
      return;
    }

    try {
      // Faz a requisição DELETE para a API
      const response = await axios.delete(`${API_URL}/usuarios/${id}`);

      if (response.status === 200) {
        // Remove o usuário da lista local
        setUsuarios((prev) => prev.filter((u) => u.id !== id));
        toast.success("Usuário deletado com sucesso!");
        handleCloseConfirmDelete();
      } else {
        toast.error("Erro ao deletar usuário");
      }
    } catch (error: any) {
      console.error("Erro ao deletar usuário:", error);

      // Exibe uma mensagem de erro com base na resposta do servidor
      if (error.response && error.response.data && error.response.data.erro) {
        toast.error(error.response.data.erro);
      } else {
        toast.error("Erro ao deletar usuário. Tente novamente.");
      }
    }
  };

  const handleShowConfirmDelete = (usuario: IUsuario) => {
    setSelectedUsuario(usuario);
    setShowConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false);
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Limpa o erro de validação da cidade sede quando usuário_cidade é alterado para "Não"
    if (name === "usuario_cidade" && value === "Não") {
      setValidationErrors((prev) => ({
        ...prev,
        id_cidade_sede: "",
      }));
    }
  };

  const handleAreaChange = (selectedOptions: IAreaOption[] | null) => {
    const selectedAreas = selectedOptions
      ? selectedOptions.map((opt) => opt.value)
      : [];
    setFormData((prev) => ({
      ...prev,
      area: selectedAreas,
    }));
  };

  const formatAreaForInput = () => {
    if (formData.area && Array.isArray(formData.area)) {
      return formData.area.join(", ");
    }
    return "";
  };

  function renderStatusBadge(situacao: TSituacao) {
    let variant: "success" | "warning" | "danger";
    let text: string;

    switch (situacao) {
      case "Ativo":
        variant = "success";
        text = "Ativo";
        break;
      case "Ausente":
        variant = "warning";
        text = "Ausente";
        break;
      case "Inativo":
        variant = "danger";
        text = "Inativo";
        break;
      default:
        return null;
    }

    return (
      <Badge
        variant="phoenix"
        bg={variant}
        className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
        style={{
          minWidth: "80px",
        }}
      >
        {text}
      </Badge>
    );
  }

  // SSI 0136 - DEBOSSAN - 24/03/2025
  // INICIO
  const handleCidadeSedeChange = (selectedOptions: any) => {
    console.log("Novas opções selecionadas:", selectedOptions);

    const selectedCidades = selectedOptions || [];
    const cidadeIds = selectedCidades.map((option: any) => option.id);
    const cidadeNomes = selectedCidades.map((option: any) => option.value);

    console.log("IDs das cidades selecionadas:", cidadeIds);
    console.log("Nomes das cidades selecionadas:", cidadeNomes);

    setFormData((prev) => ({
      ...prev,
      id_cidade_sede: cidadeIds,
      cidade_sede: cidadeNomes,
    }));

    // Limpa o erro de validação se existir
    if (validationErrors.id_cidade_sede) {
      setValidationErrors((prev: { [key: string]: string }) => ({
        ...prev,
        id_cidade_sede: "",
      }));
    }
  };

  // Função para obter as opções selecionadas de cidades
  const getSelectedCidadesOptions = () => {
    const selectedIds = formData.id_cidade_sede || [];
    console.log("Buscando opções para os IDs:", selectedIds);

    if (!selectedIds.length) return [];

    const selectedOptions = cidadeOptions.filter((option) =>
      selectedIds.includes(option.id),
    );

    console.log("Opções selecionadas:", selectedOptions);
    return selectedOptions;
  };
  // FIM
  const table = useAdvanceTable<IUsuario>({
    data: usuarios,
    columns: [
      {
        accessorKey: "nome",
        header: "Nome",
      },
      {
        accessorKey: "user",
        header: "Usuário",
      },
      {
        accessorKey: "funcao",
        header: "Função",
      },
      {
        accessorKey: "area",
        header: "Áreas",
        cell: ({ row }: any) => row.original.area.join(", "),
      },
      {
        accessorKey: "cidade_sede",
        header: "Cidades Sede",
        cell: ({ row }: any) => {
          const cidadeSede = row.original.cidade_sede;
          if (!cidadeSede) return "-";
          if (typeof cidadeSede === "string") {
            return cidadeSede.split(",").join(", ");
          }
          if (Array.isArray(cidadeSede)) {
            return cidadeSede.join(", ");
          }
          return String(cidadeSede);
        },
      },
      {
        accessorKey: "situacao",
        header: "Situação",
        cell: ({ row }: any) => renderStatusBadge(row.original.situacao),
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          const usuario: IUsuario = row.original;
          return (
            <div className="action-buttons">
              <Button
                variant="link"
                className="btn-sm text-primary p-0 m-0 icon-button"
                onClick={() => handleEditUsuarioClick(usuario)}
                title="Editar"
              >
                <Edit size={16} />
              </Button>
              <Button
                variant="link"
                className="btn-sm text-danger p-0 m-0 icon-button"
                onClick={() => handleShowConfirmDelete(usuario)}
                title="Deletar"
              >
                <Trash2 size={16} />
              </Button>
            </div>
          );
        },
      },
    ],
    pageSize: 30,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "nome", desc: false }],
    },
  });

  return (
    <div>
      <style>{styles}</style>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-4" style={{ textAlign: "left" }}>
          Cadastro de Usuários
        </h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-4 align-items-end">
              <Col xs={12} sm={4} md={4}>
                <Form.Group controlId="buscarUsuario">
                  <Form.Label>Buscar Usuários</Form.Label>
                  <SearchBox
                    placeholder="Pesquisar usuário"
                    onChange={handleSearchInputChange}
                    style={{ width: "100%" }}
                  />
                </Form.Group>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              />
              <Col xs="auto">
                <Button variant="primary" onClick={handleShow}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Adicionar Usuário
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: "phoenix-table fs-9 table-hover" }}
            />
          </div>
          <AdvanceTableFooter pagination />
        </AdvanceTableProvider>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditing ? "Editar Usuário" : "Cadastrar Usuário"}
            </Modal.Title>
          </Modal.Header>

          <Form noValidate validated={validated} onSubmit={handleSaveUsuario}>
            <Modal.Body>
              <Row className="gx-3 gy-4">
                <Col sm={6}>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, insira o nome.
                  </Form.Control.Feedback>
                </Col>

                <Col sm={6}>
                  <Form.Label>Usuário</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o usuário"
                    name="user"
                    value={formData.user}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, insira o usuário.
                  </Form.Control.Feedback>
                </Col>

                <Col sm={6}>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={
                      isEditing
                        ? "Deixe em branco para manter a senha atual"
                        : "Digite a senha"
                    }
                    name="senha"
                    value={formData.senha}
                    onChange={handleInputChange}
                    required={!isEditing}
                  />
                  {validationErrors.senha && (
                    <div className="invalid-feedback d-block">
                      {validationErrors.senha}
                    </div>
                  )}
                </Col>

                <Col sm={6}>
                  <Form.Label>Função</Form.Label>
                  <Form.Select
                    name="funcao"
                    value={formData.funcao}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="admin">Admin</option>
                    <option value="Agente">Agente</option>
                    <option value="Gestor">Gestor</option>
                  </Form.Select>
                </Col>

                <Col sm={6}>
                  <Form.Label>Situação</Form.Label>
                  <Form.Select
                    name="situacao"
                    value={formData.situacao}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Ativo">Ativo</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Inativo">Inativo</option>
                  </Form.Select>
                </Col>

                <Col sm={6}>
                  <Form.Label>Carteira Ativa</Form.Label>
                  <Form.Select
                    name="carteira_ativa"
                    value={formData.carteira_ativa}
                    onChange={handleInputChange}
                    required
                  >
                    <option value={1}>Sim</option>
                    <option value={0}>Não</option>
                  </Form.Select>
                </Col>

                <Col sm={6}>
                  <Form.Label>Usuário Cidade</Form.Label>
                  <Form.Select
                    name="usuario_cidade"
                    value={formData.usuario_cidade}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </Form.Select>
                </Col>

                <Col sm={6}>
                  <Form.Label>Advogado Externo</Form.Label>
                  <Form.Select
                    name="advogado_externo"
                    value={formData.advogado_externo}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </Form.Select>
                </Col>

                <Col sm={4}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Digite o email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, insira o email.
                  </Form.Control.Feedback>
                </Col>

                <Col sm={4}>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o telefone"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col sm={4}>
                  <Form.Label>Data de Nascimento</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Digite a data de nascimento"
                    name="dt_nascimento"
                    value={formData.dt_nascimento}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col sm={12}>
                  <Form.Label>Cidade Sede</Form.Label>
                  <ReactSelect
                    placeholder="Selecione uma ou mais cidades"
                    options={cidadeOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    isLoading={loadingCidades}
                    isDisabled={loadingCidades}
                    value={getSelectedCidadesOptions()}
                    onChange={handleCidadeSedeChange}
                    className={
                      validationErrors.id_cidade_sede ? "is-invalid" : ""
                    }
                    isClearable
                  />
                  {validationErrors.id_cidade_sede && (
                    <div className="invalid-feedback d-block">
                      {validationErrors.id_cidade_sede}
                    </div>
                  )}
                  {cidadesError && (
                    <div className="text-danger mt-1">
                      {cidadesError}{" "}
                      <Button
                        variant="link"
                        className="p-0"
                        onClick={() => fetchCidadesSede()}
                      >
                        Tentar novamente
                      </Button>
                    </div>
                  )}
                </Col>

                <Col sm={12}>
                  <Form.Label>Áreas</Form.Label>
                  <ReactSelect
                    placeholder="Selecione as áreas"
                    options={areaOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    value={formData.area.map((area) => ({
                      value: area,
                      label: area,
                    }))}
                    onChange={(newValue) =>
                      handleAreaChange(newValue as IAreaOption[])
                    }
                    isClearable
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="primary" type="submit">
                {isEditing ? "Salvar Alterações" : "Criar"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza que deseja{" "}
            <strong style={{ color: "#ef4444" }}>DELETAR</strong> o usuário{" "}
            <strong>{selectedUsuario?.nome}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmDelete}>
              Cancelar
            </Button>
            {selectedUsuario && (
              <Button
                variant="danger"
                onClick={() => handleDeleteUsuario(selectedUsuario.id)}
              >
                Deletar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Geral;
