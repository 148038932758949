//SSI 0113 - PFERRI - Ajuste na permissão do lider de squad - 14/02/2025
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "./components/UserContext";

interface RotasComPermissaoProps {
  requiredRoles?: string[];
  requiredAreas?: string[];
  requiredUsuarioCidade?: "Sim" | "Não";
  requiredAdvogadoExterno?: "Sim" | "Não";
  requiredLiderSquad?: boolean;
  requiredLiderOuAdmin?: boolean;
  children: React.ReactNode;
}

const RotasComPermissao: React.FC<RotasComPermissaoProps> = ({
  requiredRoles,
  requiredAreas,
  requiredUsuarioCidade,
  requiredLiderSquad,
  requiredAdvogadoExterno,
  requiredLiderOuAdmin,
  children,
}) => {
  const { user } = useContext(UserContext);

  // Obter o usuário do localStorage como fallback
  const storedUser = localStorage.getItem("user");
  const currentUser = user || (storedUser ? JSON.parse(storedUser) : null);

  if (!currentUser) {
    console.log("Usuário não autenticado. Redirecionando para /login.");
    return <Navigate to="/login" replace />;
  }

  const {
    area,
    funcao,
    usuario_cidade,
    advogado_externo,
    isLiderSquad,
    isAdmin,
  } = currentUser;

  // Transformar as áreas do usuário em array
  const userAreas = area ? area.split(",").map((a: string) => a.trim()) : [];
  //  SSI 011 - PFERRI - Ajuste para aceitar lider de squad alem da verificação por area, tendo em vista que o lider de squad traz um boolean e não uma string
  const areaPermitida =
    !requiredAreas ||
    requiredAreas.length === 0 ||
    userAreas.some((userArea: string) => requiredAreas.includes(userArea)) ||
    isLiderSquad;

  const funcaoPermitida =
    !requiredRoles || requiredRoles.length === 0
      ? true
      : requiredRoles.includes(funcao);

  const usuarioCidadeOk =
    requiredUsuarioCidade === undefined
      ? true
      : usuario_cidade === requiredUsuarioCidade;

  const advogadoExternoOk =
    requiredAdvogadoExterno === undefined
      ? true
      : advogado_externo === requiredAdvogadoExterno;

  //SSI 0122 - GCOLLE - Permissão página Routes isLiderSquad || Admin || Gestor - 24/02/25
  const liderSquadPermitido =
    requiredLiderSquad === true
      ? isLiderSquad ||
        funcao?.toLowerCase() === "admin" ||
        funcao?.toLowerCase() === "gestor"
      : true;

  // Combine todas as condições
  const permitido =
    areaPermitida &&
    funcaoPermitida &&
    usuarioCidadeOk &&
    advogadoExternoOk &&
    liderSquadPermitido;

  if (permitido) {
    return <>{children}</>;
  } else {
    console.log("Permissão negada. Redirecionando para /.");
    return <Navigate to="/" replace />;
  }
};

export default RotasComPermissao;
