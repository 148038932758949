// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
//SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, Card } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import { toast } from "react-toastify";
import SearchBox from "components/common/SearchBox";
import TableCondominioCidade from "./table/TableCondominioCidade";
import TableCondominioEspecifico from "./table/TableCondominioEspecifico";

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Editar Usuários Cidade", active: true },
];

interface IUsuario {
  id_func: number;
  nome: string;
  cidade_sede: string;
  [key: string]: any;
}

interface ICondominio {
  id_cond: number;
  CONDOMINIO: string;
  CIDADE: string;
  [key: string]: any;
}

const EditarUsuarioCidade: React.FC = () => {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<IUsuario | null>(
    null,
  );
  const [todosCondominios, setTodosCondominios] = useState<ICondominio[]>([]);
  const [condominiosCidade, setCondominiosCidade] = useState<ICondominio[]>([]);
  const [loadingCidade, setLoadingCidade] = useState(false);
  const [condominiosEspecificos, setCondominiosEspecificos] = useState<
    ICondominio[]
  >([]);
  const [loadingEspecificos, setLoadingEspecificos] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [condominioSelecionado, setCondominioSelecionado] =
    useState<ICondominio | null>(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchUsuarios = async () => {
    try {
      const response = await fetch(`${API_URL}/usuario-cidade/usuarios-cidade`);
      const data = await response.json();
      setUsuarios(data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  const fetchTodosCondominios = async () => {
    try {
      const response = await fetch(
        `${API_URL}/usuario-cidade/todos-condominios`,
      );
      const data = await response.json();
      setTodosCondominios(data);
    } catch (error) {
      console.error("Erro ao buscar todos os condomínios:", error);
    }
  };

  const fetchCondominiosCidadeSede = async (cidadeSede: string) => {
    setLoadingCidade(true);
    try {
      const response = await fetch(
        `${API_URL}/usuario-cidade/condominios-cidade/${cidadeSede}`,
      );
      const data = await response.json();
      setCondominiosCidade(data);
    } catch (error) {
      console.error("Erro ao buscar condomínios da cidade:", error);
    } finally {
      setLoadingCidade(false);
    }
  };

  const fetchCondominiosEspecificos = async (idFunc: number) => {
    setLoadingEspecificos(true);
    try {
      const response = await fetch(
        `${API_URL}/usuario-cidade/condominios-especificos/${idFunc}`,
      );
      const data = await response.json();

      if (data.length > 0) {
        toast.success(
          `Condomínios encontrados para o usuário ${usuarioSelecionado?.nome}`,
        );
      } else {
        toast.warn(
          `Nenhum condomínio relacionado com o usuário ${usuarioSelecionado?.nome}`,
        );
      }

      setCondominiosEspecificos(data);
    } catch (error: any) {
      toast.error(`Erro ao buscar condomínios específicos: ${error.message}`);
    } finally {
      setLoadingEspecificos(false);
    }
  };

  const handleSelecionarUsuario = (idFunc: number) => {
    const user = usuarios.find((u) => u.id_func === Number(idFunc)) || null;
    setUsuarioSelecionado(user);

    setCondominioSelecionado(null);
    setFiltro("");
    setCondominiosCidade([]);
    setCondominiosEspecificos([]);

    if (user) {
      fetchCondominiosEspecificos(user.id_func);

      if (user.cidade_sede) {
        fetchCondominiosCidadeSede(user.cidade_sede);
      }
    }
  };

  const adicionarCondominioEspecifico = async () => {
    if (!usuarioSelecionado || !condominioSelecionado) return;

    try {
      const response = await fetch(
        `${API_URL}/usuario-cidade/adicionar-condominio`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_func: usuarioSelecionado.id_func,
            id_cond: condominioSelecionado.id_cond,
          }),
        },
      );

      if (!response.ok) {
        toast.error("Erro ao adicionar condomínio.");
        return;
      }

      toast.success("Condomínio adicionado com sucesso!");
      fetchCondominiosEspecificos(usuarioSelecionado.id_func);
      setCondominioSelecionado(null);
    } catch (error: any) {
      toast.error(`Erro ao adicionar condomínio: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchUsuarios();
    fetchTodosCondominios();
  }, []);

  const condominiosFiltrados = condominiosCidade.filter((condominio) =>
    condominio.CONDOMINIO.toLowerCase().includes(filtro.toLowerCase()),
  );

  const condominiosEspecificosFiltrados = condominiosEspecificos.filter(
    (condominio) =>
      condominio.CONDOMINIO.toLowerCase().includes(filtro.toLowerCase()),
  );

  const condominiosDisponiveis = todosCondominios.filter(
    (condominio) =>
      !condominiosEspecificos.some(
        (espec) => espec.id_cond === condominio.id_cond,
      ),
  );

  return (
    <Container fluid className="py-4">
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Editar Usuários Cidade</h2>

      <Row className="mt-4">
        {/* Inicio SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025  */}
        <Col xs={12} md={6} lg={6}>
          {/* Selecionar Usuário */}
          <Form.Group className="mb-3">
            <Form.Label>Selecione o usuário</Form.Label>
            <ReactSelect
              placeholder="Selecione um usuário"
              options={usuarios.map((user) => ({
                value: user.id_func,
                label: `${user.nome} - ${user.cidade_sede}`,
              }))}
              closeMenuOnSelect={true}
              onChange={(selectedOption) => {
                const option = selectedOption as {
                  value: number;
                  label: string;
                } | null;
                if (option) handleSelecionarUsuario(option.value);
              }}
              isClearable
            />
          </Form.Group>
        </Col>
        {/* Fim SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025  */}

        <Col xs={12} md={4} lg={3}>
          {/* Filtro de condomínios (habilitado apenas se houver usuário selecionado) */}
          {usuarioSelecionado && (
            <Form.Group className="mb-3">
              <Form.Label>Filtrar Condomínios</Form.Label>
              <SearchBox
                placeholder="Digite para filtrar..."
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                style={{ width: "100%" }} // Ajusta para ocupar toda a largura disponível
              />
            </Form.Group>
          )}
        </Col>
      </Row>

      {/* Duas tabelas lado a lado */}
      <Row>
        {/* Tabela da Esquerda - Condomínios da cidade (AdvanceTable) */}
        <Col md={6} className="mb-3">
          <TableCondominioCidade
            condominios={condominiosFiltrados}
            loading={loadingCidade}
          />
        </Col>

        {/* Tabela da Direita - Condomínios Específicos do Usuário (AdvanceTable) */}
        <Col md={6}>
          <TableCondominioEspecifico
            condominios={condominiosEspecificosFiltrados}
            loading={loadingEspecificos}
          />

          {/* Campo para adicionar condomínio (abaixo da tabela da direita) */}
          {usuarioSelecionado && (
            <div className="d-flex align-items-center mt-3">
              <Form.Group className="flex-grow-1 me-2">
                <Form.Label>Adicionar Condomínio</Form.Label>
                <ReactSelect
                  placeholder="-- Selecione --"
                  options={condominiosDisponiveis.map((cond) => ({
                    value: cond.id_cond,
                    label: `${cond.CONDOMINIO} - ${cond.CIDADE}`,
                  }))}
                  value={
                    condominioSelecionado
                      ? {
                          value: condominioSelecionado.id_cond,
                          label: `${condominioSelecionado.CONDOMINIO} - ${condominioSelecionado.CIDADE}`,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    const option = selectedOption as {
                      value: number;
                      label: string;
                    } | null;
                    if (option) {
                      const cond = condominiosDisponiveis.find(
                        (c) => c.id_cond === option.value,
                      );
                      setCondominioSelecionado(cond || null);
                    } else {
                      setCondominioSelecionado(null);
                    }
                  }}
                  isClearable
                  menuPlacement="top"
                />
              </Form.Group>

              <Button
                className="mt-auto"
                variant="primary"
                onClick={adicionarCondominioEspecifico}
                disabled={!condominioSelecionado}
              >
                Adicionar
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditarUsuarioCidade;
