// SSI 0093 - MATHEUS BOTELHO - 16/01/25 - INICIO
//SSI 0120 - PFERRI -Corrigindo a responsividade e comentando os console.log - 25/02/2025

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getMonth, parseISO, isAfter, addDays } from "date-fns";
import ReactECharts from "echarts-for-react";

const AssembleiaStatus = ({ mesAtual }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [publicadas, setPublicadas] = useState(0);
  const [naoPublicadas, setNaoPublicadas] = useState(0);
  const [convocadas, setConvocadas] = useState(0);
  const [naoConvocadas, setNaoConvocadas] = useState(0);
  const [pendentes, setPendentes] = useState(0);
  const [concluidas, setConcluidas] = useState(0);
  const [faltantes, setFaltantes] = useState(0);
  const [totalAssembleias, setTotalAssembleias] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchAssembleias = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/assembleia/euro_assembleias`,
      );
      const data = response.data.euro_assembleias;

      // Filtrar pelo mês de referência
      const isFromCurrentMonth = (date) =>
        getMonth(parseISO(date)) === mesAtual;

      const assembleiasMes = data.filter((assembleia) =>
        assembleia.dt_assembleia
          ? isFromCurrentMonth(assembleia.dt_assembleia)
          : false,
      );

      // console.log("Assembleias no mês de referência:", assembleiasMes);

      // Log dos valores de assembleia_publicada
      // assembleiasMes.forEach((assembleia) => {
      //   console.log(
      //     `Assembleia ID: ${assembleia.id}, Publicada: ${assembleia.assembleia_publicada}`,
      //   );
      // });

      const totalCount = assembleiasMes.length;

      // Publicadas e Não Publicadas
      const publicadasList = assembleiasMes.filter(
        (assembleia) => assembleia.assembleia_publicada === 1,
      );
      const naoPublicadasList = assembleiasMes.filter(
        (assembleia) => assembleia.assembleia_publicada === 0,
      );
      // console.log("Publicadas:", publicadasList);
      // console.log("Não Publicadas:", naoPublicadasList);

      // Convocadas e Não Convocadas
      const convocadasList = assembleiasMes.filter(
        (assembleia) => assembleia.status_assembleia >= 2,
      );
      const naoConvocadasList = assembleiasMes.filter(
        (assembleia) => assembleia.status_assembleia < 2,
      );
      // console.log("Convocadas:", convocadasList);
      // console.log("Não Convocadas:", naoConvocadasList);

      // Documentos Pendente, Concluído e Faltante
      const pendentesList = assembleiasMes.filter((assembleia) => {
        const assembleiaDate = parseISO(assembleia.dt_assembleia);
        const limiteDate = addDays(assembleiaDate, 1);
        return (
          assembleia.status_assembleia < 8 &&
          assembleia.status_assembleia > 0 &&
          isAfter(limiteDate, new Date())
        );
      });
      const concluidasList = assembleiasMes.filter(
        (assembleia) => assembleia.status_assembleia >= 8,
      );
      const faltantesList = assembleiasMes.filter((assembleia) => {
        const assembleiaDate = parseISO(assembleia.dt_assembleia);
        const limiteDate = addDays(assembleiaDate, 1);
        return (
          assembleia.status_assembleia < 8 && isAfter(new Date(), limiteDate)
        );
      });

      // console.log("Pendentes:", pendentesList);
      // console.log("Concluídos:", concluidasList);
      // console.log("Faltantes:", faltantesList);

      // Atualizar estados
      setTotalAssembleias(totalCount);
      setPublicadas(publicadasList.length);
      setNaoPublicadas(naoPublicadasList.length);
      setConvocadas(convocadasList.length);
      setNaoConvocadas(naoConvocadasList.length);
      setPendentes(pendentesList.length);
      setConcluidas(concluidasList.length);
      setFaltantes(faltantesList.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }, [API_URL, mesAtual]);

  useEffect(() => {
    fetchAssembleias();
  }, [fetchAssembleias]);

  // Função para gerar configurações do gráfico
  const generateChartOptions = (title, data, customColors) => ({
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    series: [
      {
        name: title,
        type: "pie",
        radius: ["40%", "70%"],
        label: {
          show: true,
          position: "outside",
          formatter: "{b}: {c}",
        },
        data,
        itemStyle: {
          color: (params) => customColors[params.name],
        },
      },
    ],
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Permite que os gráficos quebrem linha se necessário
            justifyContent: "center", // Centraliza os gráficos
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* Gráfico: Publicadas vs Não Publicadas */}
          <div style={{ flex: "1 1 30%" }}>
            <ReactECharts
              option={generateChartOptions(
                "Publicadas x Não Publicadas",
                [
                  { value: publicadas, name: "Publicadas" },
                  { value: naoPublicadas, name: "Não Publicadas" },
                ],
                {
                  Publicadas: "#3874ff", // success
                  "Não Publicadas": "#31374a", // danger
                },
              )}
              style={{ width: "100%", maxWidth: "300px", height: "200px" }} // Reduz a altura do gráfico
            />
          </div>

          {/* Gráfico: Convocadas vs Não Convocadas */}
          <div style={{ flex: "1 1 30%" }}>
            <ReactECharts
              option={generateChartOptions(
                "Convocadas x Não Convocadas",
                [
                  { value: convocadas, name: "Convocadas" },
                  { value: naoConvocadas, name: "Não Convocadas" },
                ],
                {
                  Convocadas: "#3874ff", // success
                  "Não Convocadas": "#31374a", // danger
                },
              )}
              style={{ width: "100%", maxWidth: "300px", height: "200px" }} // Reduz a altura do gráfico
            />
          </div>

          {/* Gráfico: Documentos (Pendente, Concluído, Faltante) */}
          <div style={{ flex: "1 1 30%" }}>
            <ReactECharts
              option={generateChartOptions(
                "Atas publicadas",
                [
                  { value: pendentes, name: "Atas pendentes" },
                  { value: concluidas, name: "Atas publicadas" },
                  { value: faltantes, name: "Atas faltantes" },
                ],
                {
                  "Atas pendentes": "#adc5ff",
                  "Atas publicadas": "#3874ff",
                  "Atas faltantes": "#31374a",
                },
              )}
              style={{ width: "100%", maxWidth: "300px", height: "200px" }} // Reduz a altura do gráfico
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssembleiaStatus;

// SSI 0093 - MATHEUS BOTELHO - 16/01/25 - FIM
