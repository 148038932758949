//SSI 0120 - PFERRI - Corrigindo envio de notificações - 25/02/2025
import React, { useEffect, useState, useContext } from "react";
import {
  Dropdown,
  Nav,
  ToastContainer,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import classNames from "classnames";
import Avatar from "components/base/Avatar";
import NotificationDropdownMenu from "./NotificationDropdownMenu";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { useAppContext } from "providers/AppProvider";
import { NotificationsContext } from "../../../context/NotificationsContext";
import CondominioPesquisa from "pages/geral/carteiras/CondominioPesquisa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io, { Socket } from "socket.io-client"; // WebSocket
import UserContext from "../../UserContext";

// Interface da notificação
interface Notification {
  id: string;
  titulo: string;
  mensagem: string;
  type?: string;
  lida: boolean;
  data_recebida: string;
}

const NavItems: React.FC = () => {
  const {
    config: { navbarPosition },
  } = useAppContext();

  const notificationsContext = useContext(NotificationsContext);
  if (!notificationsContext) {
    console.error("❌ NotificationsContext não encontrado.");
    return null;
  }

  const { notifications, setNotifications, markAllAsRead, unreadCount } =
    notificationsContext;

  const { user } = useContext(UserContext);
  ////SSI 0120 - PFERRI configurando função para conexão do socket  - 25/02/2025
  const API_URL = process.env.REACT_APP_URL;
  // console.log(API_URL);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (user && user.id_func) {
      console.log("📡 Conectando ao WebSocket...");
      // console.log(API_URL);
      const newSocket = io(API_URL, {
        query: { userId: user.id_func, area: user.area },
      });

      const handleNewNotification = (notification: any) => {
        console.log("🔔 Notificação recebida:", notification);

        //  Atualizando o estado de notificações garantindo tipagem correta
        setNotifications((prev) => [notification, ...prev]);

        //  Exibindo a notificação via toast
        if (notification.type === "condominium") {
          toast.warning(
            "Atenção! Você tem uma nova notificação do seu condomínio!",
          );
        } else {
          toast.info("Você tem uma nova notificação!");
        }
      };

      newSocket.on("receiveNotification", handleNewNotification);

      setSocket(newSocket);

      return () => {
        console.log("❌ Desconectando WebSocket...");
        newSocket.off("receiveNotification");
        newSocket.disconnect();
      };
    }
  }, [user]);

  const markAsRead = async (notificationId: number, lida: boolean) => {
    console.log(
      `🔔 Alterando status da notificação ${notificationId} para ${
        lida ? "Lida" : "Não lida"
      }`,
    );

    const response = await fetch(
      `${API_URL}/notificacoes/lida/${notificationId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ lida: lida ? 1 : 0 }),
      },
    );

    if (response.ok) {
      const updatedNotifications = notifications.map((n) =>
        Number(n.id) === Number(notificationId) ? { ...n, lida } : n,
      );
      setNotifications(updatedNotifications);
    } else {
      console.error("Falha ao atualizar status da notificação.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="navbar-nav navbar-nav-icons flex-row align-items-center">
        {/* SSI 0090 - GCOLLE - Consulta Carteira Topbar */}
        <Nav.Item>
          <CondominioPesquisa />
        </Nav.Item>
        <Nav.Item
          className={classNames({
            "d-lg-none":
              navbarPosition === "vertical" || navbarPosition === "dual",
          })}
        >
          <Nav.Link>
            <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
          </Nav.Link>
        </Nav.Item>

        {/* Ícone do sino com Tooltip */}
        <Nav.Item className="mx-2">
          <Dropdown autoClose="outside" className="h-80">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Notificações</Tooltip>}
            >
              <Dropdown.Toggle
                as={Link}
                to="#!"
                className="dropdown-caret-none nav-link h-80 position-relative"
                variant=""
              >
                <FeatherIcon icon="bell" size={20} />
                {unreadCount > 0 && (
                  <span
                    className="badge bg-danger text-white position-absolute"
                    style={{
                      top: "7px",
                      right: "5px",
                      fontSize: "0.6rem",
                      padding: "2px 4px",
                      borderRadius: "50%",
                    }}
                  >
                    {unreadCount}
                  </span>
                )}
              </Dropdown.Toggle>
            </OverlayTrigger>
            <NotificationDropdownMenu
              notifications={notifications}
              setNotifications={setNotifications}
              markAllAsRead={markAllAsRead}
            />
          </Dropdown>
        </Nav.Item>

        {/* Ícone de avatar */}
        <Nav.Item>
          <Dropdown autoClose="outside" className="h-80">
            <Dropdown.Toggle
              as={Link}
              to="#!"
              className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-80 d-flex align-items-center"
              variant=""
            >
              {/* ---> Passe a URL do usuário para o Avatar */}
              <Avatar size="m" src={`${user?.avatar}?t=${Date.now()}`} />
            </Dropdown.Toggle>
            <ProfileDropdownMenu />
          </Dropdown>
        </Nav.Item>
      </div>
    </>
  );
};

export default NavItems;
