//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
/* //SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025 */
import React, { useState, useEffect, useContext } from "react";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import VisaoGeralTable from "./table/VisaoGeralTable";
import CondominioTable from "./table/CondominioTable";
import UsuarioTable from "./table/UsuarioTable";

// IMPORT DO CONTEXTO (ou do hook que busca do localStorage)
import UserContext from "components/UserContext";
import { Col, FormLabel } from "react-bootstrap";
import SquadTable from "./table/SquadTable";

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Fechamento Mensal", active: true },
];

const FechamentoMensal: React.FC = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId;
  const [view, setView] = useState<string>("porUsuario");

  /* //SSI 0112 - PFERRI - Adicionando permissão com base na area, função e se é lider de squad - 14/02/2025 */
  const userSquad = user?.isLiderSquad;
  const userArea = user?.area;
  const userFuncao = user?.funcao;

  const DiretorAdmin = userArea === "Diretoria" && userFuncao === "admin";
  const FinanceiroGestor = userArea === "Financeiro" && userFuncao === "Gestor";
  const LiderSquad = userSquad === true;

  useEffect(() => {
    if (!(DiretorAdmin || FinanceiroGestor)) {
      setView("porUsuario");
    }
  }, [DiretorAdmin, FinanceiroGestor]);

  const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newView = event.target.value;
    /* //SSI 0112 - PFERRI - Adicionando permissão com base na area, função e se é lider de squad - 14/02/2025 */
    // Se o usuário não tem permissão para "Visão Geral", não deixamos ele selecionar
    if (!(DiretorAdmin || FinanceiroGestor) && newView === "visaoGeral") {
      return;
    }

    setView(newView);
  };
  /* //SSI 0112 -Ajuste na label na div e no input- 14/02/2025 */
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="d-flex align-items-center mb-4">
        <h2 className="mb-0">Fechamento Mensal</h2>
      </div>
      <Col xs={12} sm={2} md={2}>
        <FormLabel>Pesquise por</FormLabel>
        <select
          className="form-select"
          style={{
            flex: 1,
            maxHeight: "37.06px",
            maxWidth: "200px",
            padding: "0.5rem 1rem",
            fontWeight: "bold",
            fontSize: "13px",
          }}
          value={view}
          onChange={handleViewChange}
        >
          {/* Exibe "Visão Geral" se for Diretor/Admin ou Financeiro/Gestor */}
          {(DiretorAdmin || userId === 27) && (
            <option value="visaoGeral">Visão Geral</option>
          )}
          <option value="porUsuario">Usuário</option>
          {(DiretorAdmin || FinanceiroGestor || LiderSquad) && (
            <option value="porCondominio">Condomínio</option>
          )}
          {(DiretorAdmin || FinanceiroGestor || LiderSquad) && (
            <option value="porSquad">Squad</option>
          )}
        </select>
      </Col>

      {/* Renderiza a tabela baseada na seleção */}
      <div>
        {view === "visaoGeral" && (DiretorAdmin || userId === 27) && (
          <VisaoGeralTable />
        )}
        {view === "porSquad" &&
          (DiretorAdmin || FinanceiroGestor || LiderSquad) && <SquadTable />}

        {view === "porCondominio" &&
          (DiretorAdmin || FinanceiroGestor || LiderSquad) && (
            <CondominioTable />
          )}
        {view === "porUsuario" && <UsuarioTable />}
      </div>
    </div>
  );
};

export default FechamentoMensal;
