/* //SSI 0112 - PFERRI - Adicionar SQUAD e melhorias visuais - 14/02/2025 */
import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Form,
  Popover,
  OverlayTrigger,
  Spinner,
  FormLabel,
} from "react-bootstrap";
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  Calendar,
} from "feather-icons-react";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import SearchBox from "components/common/SearchBox";
import axios from "axios";
import UserContext from "components/UserContext";
import FechamentoSquadGrafico from "../GraficoSquad/Grafico";

interface Squad {
  id: string; // ou number, dependendo do tipo do id
  nome_squad: string; // ou o nome que você usar na resposta da API
}

/* //SSI 0112 - PFERRI - Tela apresentando a tabela e o grafico de indicadores dos squads- 14/02/2025 */

const SquadTable: React.FC = () => {
  // VALIDAÇÕES DE MÊS/ANO (SIMPLIFICADAS)
  const setMesSeguro = (novoMes: number) => {
    if (novoMes < 1) {
      setMes(12);
    } else if (novoMes > 12) {
      setMes(1);
    } else {
      setMes(novoMes);
    }
  };

  const setAnoSeguro = (novoAno: number) => {
    if (novoAno < 1900) {
      setAno(1900);
    } else if (novoAno > 2100) {
      setAno(2100);
    } else {
      setAno(novoAno);
    }
  };

  // STATES
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [dados, setDados] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSquad, setSelectedSquad] = useState<string>("");
  const [squads, setSquads] = useState<Squad[]>([]);
  const [loadingSquads, setLoadingSquads] = useState(true);
  const { user } = useContext(UserContext);
  const userSquad = user?.isLiderSquad;
  const LiderSquad = userSquad === true;
  const userId = user?.userId;
  const userArea = user?.area;
  const userFuncao = user?.funcao;

  const DiretorAdmin = userArea === "Diretoria" && userFuncao === "admin";
  const FinanceiroGestor = userArea === "Financeiro" && userFuncao === "Gestor";

  // FUNÇÕES DE APOIO
  /**
   * Converte uma string como "1/26" em { num: 1, den: 26 }
   * Caso a string seja inválida, retorna { num: 0, den: 0 }
   */
  const parseFraction = (value: string) => {
    if (!value) return { num: 0, den: 0 };
    const [num, den] = value.split("/");
    return {
      num: parseInt(num, 10) || 0,
      den: parseInt(den, 10) || 0,
    };
  };

  /**
   * Para uma coluna específica (ex: "reinf_enviado"),
   * soma todos os numeradores e denominadores dos itens de 'dados'.
   */
  const getColumnTotals = (key: string) => {
    let sumNum = 0;
    let sumDen = 0;
    dados.forEach((item) => {
      const { num, den } = parseFraction(item[key]);
      sumNum += num;
      sumDen += den;
    });
    return { sumNum, sumDen };
  };

  /**
   * Retorna o texto do cabeçalho da coluna com N/Total,
   * onde N é a soma dos numeradores e Total é a soma dos denominadores.
   */
  const renderColumnHeader = (titulo: string, key: string) => {
    const { sumNum, sumDen } = getColumnTotals(key);
    return (
      <div style={{ textAlign: "center" }}>
        <div>{titulo}</div>
        <div style={{ fontSize: "12px", color: "gray" }}>
          {sumNum}/{sumDen}
        </div>
      </div>
    );
  };

  // CONFIGURANDO A TABELA
  const table = useAdvanceTable({
    data: dados,
    columns: [
      {
        accessorKey: "colaborador",
        header: "Colaborador",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "reinf_enviado",
        header: () => renderColumnHeader("ReInf", "reinf_enviado"),
      },
      {
        accessorKey: "leitura_enviado",
        header: () => renderColumnHeader("Leitura", "leitura_enviado"),
      },
      {
        accessorKey: "consumos_individuais_enviado",
        header: () =>
          renderColumnHeader(
            "Consumos Individuais",
            "consumos_individuais_enviado",
          ),
      },
      {
        accessorKey: "reservas_enviado",
        header: () => renderColumnHeader("Reservas", "reservas_enviado"),
      },
      {
        accessorKey: "balancetes_enviado",
        header: () => renderColumnHeader("Balancetes", "balancetes_enviado"),
      },
      {
        accessorKey: "boleto_enviado",
        header: () => renderColumnHeader("Boleto", "boleto_enviado"),
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
  });

  // BUSCA DE DADOS
  const fetchDados = async () => {
    setLoading(true);
    try {
      const squad_id = selectedSquad || user?.squad_id; // Certifique-se de que o valor está correto

      if (!squad_id) {
        console.error("Não foi possível obter o squad_id do usuário");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/financeiro/squad`,
        {
          params: { mes, ano, squad_id },
        },
      );
      console.log(Headers);

      console.log("squad_id", squad_id);

      // Filtra quem não tem valor relevante ("0/0" em todas as colunas)
      const filteredData = response.data.fechamento_condominios.filter(
        (item: any) => {
          const semDados =
            item.reinf_enviado === "0/0" &&
            item.leitura_enviado === "0/0" &&
            item.consumos_individuais_enviado === "0/0" &&
            item.reservas_enviado === "0/0" &&
            item.balancetes_enviado === "0/0" &&
            item.boleto_enviado === "0/0";
          return !semDados;
        },
      );

      console.log(filteredData);
      setDados(filteredData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  // Buscar squads disponíveis ao carregar
  useEffect(() => {
    const fetchSquads = async () => {
      setLoadingSquads(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/financeiro/squad/squads`,
        );
        console.log("Resposta da API de Squads:", response.data);

        if (response.data.squads && response.data.squads.length > 0) {
          setSquads(response.data.squads);

          // Se o usuário já tem um squadId, usar ele por padrão
          if (!selectedSquad) {
            setSelectedSquad(user?.squad_id || response.data.squads[0].id);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar squads:", error);
      } finally {
        setLoadingSquads(false);
      }
    };

    fetchSquads();
  }, [user?.squad_id]); // Executa quando o usuário loga

  // HOOKS
  useEffect(() => {
    fetchDados();
  }, [mes, ano, selectedSquad]);

  useEffect(() => {
    if (!selectedSquad && squads.length > 0) {
      setSelectedSquad(user?.squad_id || squads[0].id);
    }
  }, [selectedSquad, squads, user?.squad_id]);

  // FUNÇÃO DE MUDANÇA DE SELEÇÃO DO SQUAD
  const handleSquadChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSquad(event.target.value);
  };

  // FILTRO GLOBAL
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
    const searchTerm = e.target.value.toLowerCase();
    const filteredAndSortedData = dados
      .filter((item) => item.colaborador.toLowerCase().includes(searchTerm))
      .sort((a, b) => a.colaborador.localeCompare(b.colaborador)); // Ordenação alfabética

    table.setGlobalFilter(searchTerm);
    setDados(filteredAndSortedData);
  };

  // FORMATANDO O PERÍODO
  const formatPeriodoExtenso = () => {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    return `${meses[mes - 1]} ${ano}`;
  };

  // POPOVER (MÊS/ANO)
  const popover = (
    <Popover id="popover-calendar">
      <Popover.Body>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "220px", gap: "10px" }}
        >
          <select
            value={mes}
            onChange={(e) => setMesSeguro(Number(e.target.value))}
            className="form-select"
            style={{ width: "120px", textAlign: "center" }}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
              <option key={m} value={m}>
                {
                  [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro",
                  ][m - 1]
                }
              </option>
            ))}
          </select>
          <select
            value={ano}
            onChange={(e) => setAnoSeguro(Number(e.target.value))}
            className="form-select"
            style={{ width: "100px", textAlign: "center" }}
          >
            {Array.from(
              { length: 10 },
              (_, i) => new Date().getFullYear() - i,
            ).map((a) => (
              <option key={a} value={a}>
                {a}
              </option>
            ))}
          </select>
        </div>
      </Popover.Body>
    </Popover>
  );
  /* //SSI 0141 - PFERRI - Corrigindo alteração de mês - 28/03/2025 */
  const handleMesAnterior = () => {
    setMes((prevMes) => {
      if (prevMes === 1) {
        setAno((prevAno) => prevAno - 1);
        return 12;
      }
      return prevMes - 1;
    });
  };

  const handleMesPosterior = () => {
    setMes((prevMes) => {
      if (prevMes === 12) {
        setAno((prevAno) => prevAno + 1);
        return 1;
      }
      return prevMes + 1;
    });
  };

  // RENDER
  return (
    <div>
      <Row className="g-4 align-items-center mb-4">
        {(DiretorAdmin || userId === 27 || FinanceiroGestor) && (
          <Col xs={12} sm={4} md={4}>
            <FormLabel>Selecione o Squad</FormLabel>
            <select
              className="form-select"
              value={selectedSquad}
              onChange={handleSquadChange}
              style={{
                flex: 1,

                maxHeight: "37.06px",
                maxWidth: "200px",
                padding: "0.5rem 1rem",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {loadingSquads ? (
                <option value="">Carregando...</option>
              ) : squads.length === 0 ? (
                <option value="">Nenhum Squad disponível</option>
              ) : (
                squads.map((squad) => (
                  <option key={squad.id} value={squad.id}>
                    {squad.nome_squad}
                  </option>
                ))
              )}
            </select>
          </Col>
        )}
        {/* /* //SSI 0141 - PFERRI - Corrigindo alteração de mês - 28/03/2025 */}
        <Col xs={12} sm={3} md={3} className="ms-auto">
          <Form.Group controlId="periodoExtenso">
            <Form.Label>Selecionar Período</Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ChevronsLeft
                size={16}
                style={{ cursor: "pointer", marginRight: "4px" }}
                onClick={() => setAno((prev) => prev - 1)}
              />
              <ChevronLeft
                size={16}
                style={{ cursor: "pointer", marginRight: "8px" }}
                onClick={() => {
                  if (mes === 1) {
                    setAno((prev) => prev - 1);
                    setMes(12);
                  } else {
                    setMes((prev) => prev - 1);
                  }
                }}
              />
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
                rootClose
              >
                <div
                  style={{
                    position: "relative",
                    flexGrow: 1,
                    cursor: "pointer",
                  }}
                >
                  <Form.Control
                    readOnly
                    placeholder="Selecionar período"
                    style={{
                      border: "1px solid #d3d3d3",
                      borderRadius: "6px",
                      paddingLeft: "40px",
                    }}
                    value={formatPeriodoExtenso()}
                  />
                  <Calendar
                    size={16}
                    style={{
                      color: "gray",
                      position: "absolute",
                      left: "14px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </OverlayTrigger>
              <ChevronRight
                size={16}
                style={{ cursor: "pointer", marginLeft: "8px" }}
                onClick={() => {
                  if (mes === 12) {
                    setAno((prev) => prev + 1);
                    setMes(1);
                  } else {
                    setMes((prev) => prev + 1);
                  }
                }}
              />
              <ChevronsRight
                size={16}
                style={{ cursor: "pointer", marginLeft: "4px" }}
                onClick={() => setAno((prev) => prev + 1)}
              />
            </div>
          </Form.Group>
        </Col>
        {(DiretorAdmin || userId === 27 || FinanceiroGestor || LiderSquad) && (
          <div>
            <FechamentoSquadGrafico
              squadId={selectedSquad || user?.squad_id} // Passando o squadId
              mes={mes} // Passando o mês selecionado
              ano={ano} // Passando o ano selecionado
            />
          </div>
        )}
      </Row>
    </div>
  );
};

export default SquadTable;
