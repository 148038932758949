// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Form, Button, Card, Spinner } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

interface IColaborador {
  id_func: number;
  nome: string;
  pesoTotal?: number;
  numeroCondominios?: number;
  area?: string;
}

interface IEmpreendimento {
  id_cond: number;
  CONDOMINIO: string;
  peso: number;
}

interface ITransfer {
  id_cond: number;
  id_func_novo: string;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Transferência de Condomínios", active: true },
];

const TransferenciaCondominio: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [colaboradores, setColaboradores] = useState<IColaborador[]>([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState<IColaborador | null>(null);

  const [condominios, setCondominios] = useState<IEmpreendimento[]>([]);
  const [colaboradoresArea, setColaboradoresArea] = useState<IColaborador[]>(
    [],
  );
  const [numeroCondominios, setNumeroCondominios] = useState(0);
  const [totalPeso, setTotalPeso] = useState(0);
  const [numColaboradoresDist, setNumColaboradoresDist] = useState(1);
  const [transferencias, setTransferencias] = useState<ITransfer[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [
    selectedColaboradoresParaTransferencia,
    setSelectedColaboradoresParaTransferencia,
  ] = useState<(IColaborador | null)[]>([]);

  useEffect(() => {
    setSelectedColaboradoresParaTransferencia(
      Array(numColaboradoresDist).fill(null),
    );
  }, [numColaboradoresDist]);

  useEffect(() => {
    const carregarColaboradores = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/transferir-carteira/colaboradores`,
        );
        setColaboradores(response.data);
      } catch (error) {
        console.error("Erro ao buscar colaboradores:", error);
      }
    };
    carregarColaboradores();
  }, [API_URL]);

  useEffect(() => {
    if (!colaboradorSelecionado) {
      setCondominios([]);
      setTransferencias([]);
      setNumeroCondominios(0);
      setTotalPeso(0);
      setColaboradoresArea([]);
      return;
    }

    const carregarCondominios = async () => {
      try {
        // 1) Pega dados do colaborador principal
        const { data } = await axios.get(
          `${API_URL}/transferir-carteira/condominios-colaborador/${colaboradorSelecionado.id_func}`,
        );

        setCondominios(data.empreendimentos || []);
        setNumeroCondominios(data.numeroCondominios || 0);
        setTotalPeso(data.totalPeso || 0);

        const listaTransferencias = (data.empreendimentos || []).map(
          (cond: IEmpreendimento) => ({
            id_cond: cond.id_cond,
            id_func_novo: "",
          }),
        );
        setTransferencias(listaTransferencias);

        // 2) Buscar todos os colaboradores da mesma área
        const respColabArea = await axios.get(
          `${API_URL}/transferir-carteira/colaboradores-mesma-area/${colaboradorSelecionado.id_func}`,
        );
        const colabs = respColabArea.data || [];

        if (!colabs.length) {
          setColaboradoresArea([]);
          return;
        }

        // 3) Monta a query de vários ids
        const ids = colabs.map((c: any) => c.id_func).join(",");
        // 4) Chama a rota que traz tudo de uma vez
        const respMulti = await axios.get(
          `${API_URL}/ausencia/condominios-colaboradores?ids=${ids}`,
        );
        // respMulti.data = { data: [ { id_func, empreendimentos, pesoTotal }, ... ] }
        const multiArray = respMulti.data.data || [];

        // 5) Unir as infos
        const merged = colabs.map((colab: any) => {
          const found = multiArray.find(
            (item: any) => item.id_func === colab.id_func,
          );
          return {
            ...colab,
            empreendimentos: found?.empreendimentos || [],
            pesoTotal: found?.pesoTotal || 0,
            quantidade: found?.empreendimentos?.length || 0,
          };
        });

        setColaboradoresArea(merged);
      } catch (error) {
        console.error("Erro ao buscar condomínios/colaboradores área:", error);
      }
    };

    carregarCondominios();
  }, [colaboradorSelecionado, API_URL]);

  const handleSelectColaborador = (option: any) => {
    if (!option) {
      setColaboradorSelecionado(null);
      return;
    }
    setColaboradorSelecionado(option.value);
  };

  const atualizarTransferencia = (index: number, idFuncNovo: string) => {
    const novasTransferencias = [...transferencias];
    novasTransferencias[index].id_func_novo = idFuncNovo;
    setTransferencias(novasTransferencias);
  };

  const handleDistribute = () => {
    const isAnyInvalid = selectedColaboradoresParaTransferencia.some(
      (col) => !col || !col.id_func,
    );
    if (isAnyInvalid) {
      toast.error(
        "Por favor, selecione colaboradores válidos para distribuição.",
      );
      return;
    }

    const updatedTransfers = [...transferencias];
    for (let i = 0; i < updatedTransfers.length; i++) {
      const colabIndex = i % numColaboradoresDist;
      const colab = selectedColaboradoresParaTransferencia[colabIndex];
      if (colab) {
        updatedTransfers[i].id_func_novo = colab.id_func.toString();
      }
    }
    setTransferencias(updatedTransfers);
  };

  const limparDistribuicoes = () => {
    const reset = transferencias.map((t) => ({ ...t, id_func_novo: "" }));
    setTransferencias(reset);
  };

  // Calcular novo peso por colaborador em tempo real
  const calcularNovoPesoPorColaborador = useMemo(() => {
    const pesosPorColaborador: Record<string, number> = {};

    // Inicializa com pesos atuais
    colaboradoresArea.forEach((colab) => {
      const idFuncStr = colab.id_func.toString();
      pesosPorColaborador[idFuncStr] = colab.pesoTotal ? colab.pesoTotal : 0;
    });

    // Soma pesos dos condomínios transferidos
    condominios.forEach((cond, index) => {
      const colaboradorEscolhido = transferencias[index].id_func_novo;
      if (colaboradorEscolhido) {
        pesosPorColaborador[colaboradorEscolhido] =
          (pesosPorColaborador[colaboradorEscolhido] || 0) + cond.peso;
      }
    });

    return pesosPorColaborador;
  }, [transferencias, colaboradoresArea, condominios]);

  const realizarTransferencias = async () => {
    try {
      setLoading(true);
      setMessage("Transferindo Carteira...");

      if (!colaboradorSelecionado) {
        toast.error("Nenhum colaborador selecionado.");
        return;
      }

      const areaDoColaborador = colaboradorSelecionado.area || "";

      const transferenciasValidas = transferencias
        .filter((t) => t.id_func_novo)
        .map((t) => ({
          ...t,
          id_func_atual: colaboradorSelecionado.id_func,
          area: areaDoColaborador,
        }));

      if (transferenciasValidas.length > 0) {
        await axios.put(
          `${API_URL}/transferir-carteira/transferir-condominios`,
          transferenciasValidas,
        );

        toast.success("Transferências realizadas com sucesso!");
        window.location.reload();
      } else {
        toast.error("Nenhuma transferência válida para realizar.");
      }
    } catch (error) {
      console.error("Erro ao realizar transferências:", error);
      toast.error(
        "Erro ao realizar transferências. Veja o console para mais detalhes.",
      );
    } finally {
      setLoading(false);
      setMessage("");
    }
  };

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "CONDOMINIO",
        header: "Condomínio",
      },
      {
        accessorKey: "peso",
        header: "Peso",
      },
      {
        accessorKey: "novoColaborador",
        header: "Novo Colaborador",
        cell: ({ row }: any) => {
          const index = row.index;
          return (
            <Form.Select
              value={transferencias[index]?.id_func_novo || ""}
              onChange={(e) => atualizarTransferencia(index, e.target.value)}
            >
              <option value="">Selecionar...</option>
              {colaboradoresArea.map((col) => (
                <option key={col.id_func} value={col.id_func}>
                  {col.nome}
                </option>
              ))}
            </Form.Select>
          );
        },
      },
      {
        accessorKey: "pesoAtual",
        header: "Peso Atual",
        cell: ({ row }: any) => {
          const index = row.index;
          const idFuncNovo = transferencias[index]?.id_func_novo;
          if (!idFuncNovo) return "-";
          const colab = colaboradoresArea.find(
            (c) => c.id_func.toString() === idFuncNovo,
          );
          return colab?.pesoTotal || 0;
        },
      },
      {
        accessorKey: "novoPeso",
        header: "Novo Peso",
        cell: ({ row }: any) => {
          const index = row.index;
          const idFuncNovo = transferencias[index]?.id_func_novo;
          if (!idFuncNovo) return "-";
          return calcularNovoPesoPorColaborador[idFuncNovo] || 0;
        },
      },
    ];
  }, [colaboradoresArea, transferencias, calcularNovoPesoPorColaborador]);

  const tableData = useMemo(() => {
    return condominios.map((c) => ({ ...c }));
  }, [condominios]);

  const tableInstance = useAdvanceTable({
    data: tableData,
    columns: columns,
    pageSize: 50,
    pagination: true,
    sortable: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "80vh",
      }}
    >
      <Row className="mx-0">
        {/* Coluna Esquerda - Filtros, Tabela, etc. */}
        <Col xs={12} xl={9} style={{ paddingLeft: "2rem" }}>
          <ToastContainer />
          <PageBreadcrumb items={defaultBreadcrumbItems} />
          <h2 className="mb-4">Transferência de Condomínios</h2>

          {/* Seletor de colaborador principal */}
          <Row>
            <Col xs={12} md={4}>
              <Form.Label>Colaborador Atual</Form.Label>
              <ReactSelect
                options={colaboradores.map((col) => ({
                  value: col,
                  label: col.nome,
                }))}
                placeholder="Selecione um colaborador"
                value={
                  colaboradorSelecionado
                    ? {
                        value: colaboradorSelecionado,
                        label: colaboradorSelecionado.nome,
                      }
                    : null
                }
                onChange={handleSelectColaborador}
                closeMenuOnSelect={true}
                isClearable
              />
            </Col>

            <Col xs={6} md={2}>
              <Form.Label>Empreendimentos</Form.Label>
              <Form.Control value={numeroCondominios} readOnly />
            </Col>

            <Col xs={6} md={2}>
              <Form.Label>Peso Total</Form.Label>
              <Form.Control value={totalPeso} readOnly />
            </Col>

            <Col xs={4}>
              <Form.Label>Quantos Colaboradores Distribuir</Form.Label>
              <Form.Select
                value={numColaboradoresDist}
                onChange={(e) =>
                  setNumColaboradoresDist(Number(e.target.value))
                }
              >
                {[...Array(10).keys()].map((n) => (
                  <option key={n + 1} value={n + 1}>
                    {n + 1}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          {/* Tabela (AdvanceTable) */}
          <Row className="mt-6">
            {colaboradorSelecionado ? (
              <div
                style={{
                  maxHeight: "524px",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  padding: "1rem",
                }}
                className="bg-white border-top border-bottom border-200 position-relative top-1"
              >
                <AdvanceTableProvider {...tableInstance}>
                  <AdvanceTable
                    tableProps={{
                      className:
                        "phoenix-table fs-9 table-hover text-center align-left mb-0",
                    }}
                  />
                  {tableInstance.getRowModel().rows.length === 0 && (
                    <div className="text-center py-10">
                      <p className="text-muted">Nenhum condomínio encontrado</p>
                    </div>
                  )}
                </AdvanceTableProvider>
              </div>
            ) : (
              <div className="text-muted text-center mt-10">
                Selecione um colaborador para ver seus condomínios
              </div>
            )}
          </Row>
        </Col>

        {/* Coluna Direita - Escolher colaboradores para distribuição */}
        <Col xs={12} xl={3}>
          <Card className="mt-10">
            <Card.Header>
              <h5>Distribuição Automática</h5>
            </Card.Header>
            <div style={{ maxHeight: "504px", overflowY: "auto" }}>
              {selectedColaboradoresParaTransferencia.map(
                (colabTemp, index) => (
                  <Col xs={12} key={index} className="mb-1">
                    <Card.Body className="p-2">
                      <Form.Label>Colaborador {index + 1}</Form.Label>
                      <ReactSelect
                        options={colaboradoresArea.map((colab) => ({
                          value: colab,
                          label: colab.nome,
                        }))}
                        placeholder={`Selecione o colaborador ${index + 1}`}
                        value={
                          colabTemp
                            ? {
                                value: colabTemp,
                                label: colabTemp.nome,
                              }
                            : null
                        }
                        onChange={(option) => {
                          const chosen = (option as { value: IColaborador })
                            ?.value;
                          const updated = [
                            ...selectedColaboradoresParaTransferencia,
                          ];
                          updated[index] = chosen || null;
                          setSelectedColaboradoresParaTransferencia(updated);
                        }}
                        closeMenuOnSelect={true}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          container: (provided) => ({
                            ...provided,
                            position: "relative",
                          }),
                        }}
                        isClearable
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                      />
                    </Card.Body>
                  </Col>
                ),
              )}
            </div>
            <div className="d-flex justify-content-center gap-2 my-3">
              <Button
                variant="outline-primary"
                onClick={handleDistribute}
                size="sm"
              >
                Distribuir Condomínios
              </Button>
              <Button
                variant="outline-warning"
                onClick={limparDistribuicoes}
                size="sm"
              >
                Limpar Distribuições
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Botão Final de Transferência */}
      <Row
        className="mx-0"
        style={{
          backgroundColor: "#f8f9fa",
          paddingTop: "2rem",
        }}
      >
        <div className="d-flex justify-content-end w-100">
          {/* Quando houver pelo menos um condomínio selecionado, exibir o botão */}
          {!loading && transferencias.some((t) => t.id_func_novo) && (
            <Button
              variant="outline-success"
              onClick={realizarTransferencias}
              className="me-12"
            >
              Realizar Transferência
            </Button>
          )}
          {loading && (
            <div className="d-flex flex-column align-items-center me-5">
              <Spinner animation="border" />
              <p className="mt-2">{message}</p>
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default TransferenciaCondominio;
