// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
// SSI 0118 - PFERRI - Corrigindo a rota e exibição referentes a minha carteira - 06/03/2025
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import UserContext from "components/UserContext";

interface ICondominio {
  id_cond: number;
  CONDOMINIO: string;
  peso?: number;
  [key: string]: any;
}

interface IEmpreendimentosResponse {
  condominios: ICondominio[];
  [key: string]: any;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Minha Carteira", active: true },
];

const MinhaCarteira: React.FC = () => {
  const { user } = useContext(UserContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const [empreendimentos, setEmpreendimentos] = useState<ICondominio[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalEmpreendimentos, setTotalEmpreendimentos] = useState(0);
  const [pesoEmpreendimentos, setPesoEmpreendimentos] = useState(0);
  const [totalRedirecionados, setTotalRedirecionados] = useState(0);
  const [pesoRedirecionados, setPesoRedirecionados] = useState(0);

  const totalGeral = totalEmpreendimentos + totalRedirecionados;
  const pesoTotal = pesoEmpreendimentos + pesoRedirecionados;

  // Buscar dados do usuário e fazer as chamadas
  // Filtra e gerencia os dados para exibição correta
  useEffect(() => {
    if (!user) return;
    setLoading(true);
    const fetchCondominiosAtivos = async (idsCond: number[]) => {
      try {
        const response = await fetch(
          `${API_URL}/minha-carteira/condominios-ativos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ idsCond }),
          },
        );
        const data = await response.json();
        return data.idsAtivos;
      } catch (error) {
        console.error("Erro ao buscar condomínios ativos:", error);
        return [];
      }
    };

    Promise.all([
      fetch(`${API_URL}/minha-carteira/redirecionamentos/${user.id_func}`).then(
        (res) => res.json(),
      ),
      fetch(`${API_URL}/minha-carteira/empreendimentos/${user.id_func}`).then(
        (res) => res.json(),
      ),
    ])
      .then(async ([dadosRedirecionados, dadosEmpreendimentos]) => {
        // console.log("🔹 DadosEmpreendimentos recebidos:", dadosEmpreendimentos);
        // console.log("🔹 DadosRedirecionados recebidos:", dadosRedirecionados);

        // 🔎 Filtra e separa os dados corretamente
        const empreendimentosGerenciados =
          dadosEmpreendimentos?.condominios || [];
        const empreendimentosRedirecionados =
          dadosRedirecionados?.condominios || [];

        // 🏢 Total de empreendimentos GERENCIADOS DIRETAMENTE
        const totalEmp = empreendimentosGerenciados.length;
        const pesoEmp = empreendimentosGerenciados.reduce(
          (acc: number, curr: ICondominio) => acc + (curr.peso || 1),
          0,
        );

        // 🔄 Total de empreendimentos REDIRECIONADOS
        const totalRedir = empreendimentosRedirecionados.length;
        const pesoRedir = empreendimentosRedirecionados.reduce(
          (acc: number, curr: ICondominio) => acc + (curr.peso || 1),
          0,
        );

        // ✅ Atualiza os estados corretamente
        setTotalEmpreendimentos(totalEmp);
        setPesoEmpreendimentos(pesoEmp);
        setTotalRedirecionados(totalRedir);
        setPesoRedirecionados(pesoRedir);

        // 🔥 Atualiza os empreendimentos corretamente
        setEmpreendimentos(empreendimentosGerenciados);

        setErrorMessage("");
      })
      .catch((error) => {
        console.error("Erro ao buscar empreendimentos:", error);
        setErrorMessage("Erro ao carregar os dados. Tente novamente.");
      })
      .finally(() => setLoading(false));
  }, [user, API_URL]);

  // Preparar dados para a tabela
  const tableData = useMemo(() => {
    return empreendimentos;
  }, [empreendimentos]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "CONDOMINIO",
        header: () => <strong>Condomínio</strong>,
      },
      {
        accessorKey: "peso",
        header: () => <strong>Peso</strong>,
        cell: ({ row }: any) => {
          const item = row.original as ICondominio;
          return item?.peso ?? 1;
        },
      },
    ];
  }, []);

  const tableInstance = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    pageSize: 40,
    pagination: true,
    initialState: {},
  });

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Minha Carteira</h2>

      <Row>
        {/* Coluna principal - tabela */}
        <Col xs={12} xl={9}>
          <Card>
            <Card.Header>
              <h4 className="mb-0">
                <strong>Empreendimentos do usuário</strong>
              </h4>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="d-flex flex-column justify-content-center align-items-center py-4">
                  <Spinner animation="border" />
                  <span className="mt-3">Carregando dados, aguarde...</span>
                </div>
              ) : (
                <>
                  {errorMessage && (
                    <div className="text-danger mb-3">{errorMessage}</div>
                  )}
                  {!errorMessage && (
                    <div
                      style={{
                        maxHeight: "500px",
                        overflowY: "auto",
                        border: "1px solid #ddd",
                        padding: "1rem",
                      }}
                      className="bg-white border-top border-bottom border-200 position-relative top-1"
                    >
                      <AdvanceTableProvider {...tableInstance}>
                        <AdvanceTable
                          tableProps={{
                            className:
                              "phoenix-table fs-9 table-hover text-center align-left mb-0",
                          }}
                        />
                        {tableInstance.getRowModel().rows.length === 0 && (
                          <div className="text-center py-10">
                            <p className="text-muted">
                              Nenhum condomínio encontrado
                            </p>
                          </div>
                        )}
                      </AdvanceTableProvider>
                    </div>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Coluna lateral - sumário */}
        <Col
          xs={12}
          xl={3}
          className="d-flex flex-column flex-sm-row flex-xl-column gap-xl-9 gap-2 mt-2"
        >
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-primary fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Total de Empr.:</strong>
              <span className="fs-7 fs-sm-8">{totalEmpreendimentos}</span>
            </div>
          </div>

          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-primary fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Total:</strong>
              <span className="fs-7 fs-sm-8">{pesoEmpreendimentos}</span>
            </div>
          </div>

          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-success fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">
                Redirecionamentos:
              </strong>
              <span className="fs-7 fs-sm-8">{totalRedirecionados}</span>
            </div>
          </div>

          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-success fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Redir.:</strong>
              <span className="fs-7 fs-sm-8">{pesoRedirecionados}</span>
            </div>
          </div>

          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-info fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Total Geral:</strong>
              <span className="fs-7 fs-sm-8">{totalGeral}</span>
            </div>
          </div>

          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-info fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Geral:</strong>
              <span className="fs-7 fs-sm-8">{pesoTotal}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MinhaCarteira;
