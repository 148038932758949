// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
// SSI 0111 - GCOLLE - Adicionando editar carteira para ter CADASTRO, e modificando lógica squad para apenas verificar o RH e Financeiro - 06/02/2025
// SSI 0117 - PFERRI - Criar rota de integração de condomínios e corrigir o cadastro/atualização de carteiras - 21/02/2025
// SSI 125 - GCOLLE - Editar Carteira criando regra de cobrança para criar nova ou escolher a padrão - 28/02/2025
// SSI 0118 - PFERRI - Verificação adicional para os campos obrigatórios, dia de pagamento/pagemnto adiantado - 06/03/2025
// SSI 0126 - PFERRI - Trazer usuarios ativos e com mais de uma area - 07/03/2025
// SSI 0136 - DEBOSSAN - API de Cidade e Estado na Carteira, Adicionar e Remover Cidade Sede - 24/03/2025
import React, { useState, useEffect } from "react";
import Button from "components/base/Button";
import ReactSelect from "components/base/ReactSelect";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { Card, Col, Form, Row, Modal, ListGroup } from "react-bootstrap";
import {
  faAngleDown,
  faAngleUp,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

// Estilos para o modal embaçadoCobrança Automática

const modalStyles = `
  .modal-blur {
    filter: brightness(0.4);
    pointer-events: none;
  }
  .modal-backdrop {
    z-index: 1040;
  }
  .modal {
    z-index: 1050;
  }
  .modal-dialog {
    z-index: 1055;
  }
  .hover-opacity-100:hover {
    opacity: 1 !important;
  }
  .transition-opacity {
    transition: opacity 0.2s ease-in-out;
  }
  .btn-trash {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5.2em;
  }
  .btn-trash .trash-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .btn-trash:hover .trash-icon {
    opacity: 1;
  }
  .cidade-table-row {
    position: relative;
  }
  .cidade-table-row td {
    vertical-align: middle;
  }
  .cidade-table-row td:last-child {
    width: 80px;
    padding: 0;
    text-align: center;
  }
  .cidade-table-row .icon-button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
  .cidade-table-row:hover .icon-button {
    opacity: 1;
  }
  .icon-button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .feather {
    width: 16px;
    height: 16px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

interface ICondominio {
  id_cond: number;
  nome: string;
  cidade?: string;
  uf?: string;
}

interface IUsuario {
  id_func: number;
  nome: string;
  advogado_externo?: "Sim" | "Não";
}

interface ISelectOption<T> {
  value: T;
  label: string;
}

interface ICidadeSede {
  id: number;
  cidade_sede: string;
  cidade_sede_estado: string;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Editar Carteira", active: true },
];

const EditarCarteira = () => {
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<ICondominio | null>(null);

  // Listas de usuários
  const [usuariosFinanceiro, setUsuariosFinanceiro] = useState<IUsuario[]>([]);
  const [usuariosCobranca, setUsuariosCobranca] = useState<IUsuario[]>([]);
  const [usuariosRH, setUsuariosRH] = useState<IUsuario[]>([]);
  const [usuariosComunicados, setUsuariosComunicados] = useState<IUsuario[]>(
    [],
  );
  const [usuariosAssembleias, setUsuariosAssembleias] = useState<IUsuario[]>(
    [],
  );
  const [usuariosCadastro, setUsuariosCadastro] = useState<IUsuario[]>([]);

  // Selecionados
  const [selectedFinanceiro, setSelectedFinanceiro] = useState<IUsuario | null>(
    null,
  );
  const [selectedCobranca, setSelectedCobranca] = useState<IUsuario | null>(
    null,
  );
  const [selectedRH, setSelectedRH] = useState<IUsuario | null>(null);
  const [selectedComunicados, setSelectedConsultoriaNotificacoes] =
    useState<IUsuario | null>(null);
  const [selectedAssembleias, setSelectedAssembleias] =
    useState<IUsuario | null>(null);
  const [selectedCadastro, setSelectedCadastro] = useState<IUsuario | null>(
    null,
  );

  // Pesos
  const [pesoFinanceiro, setPesoFinanceiro] = useState("");
  const [pesoCobranca, setPesoCobranca] = useState("");
  const [pesoRH, setPesoRH] = useState("");
  const [pesoComunicados, setPesoConsultoriaNotificacoes] = useState("");
  const [pesoAssembleias, setPesoAssembleias] = useState("");
  const [pesoCadastro, setPesoCadastro] = useState("");

  // Outros campos
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [possuiAgendamento, setPossuiAgendamento] = useState("");
  const [missingCondosData, setMissingCondosData] = useState<{
    missingCondos: ICondominio[];
    totalDimCondos: number;
    totalEuroCarteiras: number;
  }>({
    missingCondos: [],
    totalDimCondos: 0,
    totalEuroCarteiras: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [carteiraData, setCarteiraData] = useState<any>({});
  const [idPholha, setIdPholha] = useState("");
  const [diaPagamentoSalarios, setDiaPagamentoSalarios] = useState("");
  const [diaPagamentoAdiantamento, setDiaPagamentoAdiantamento] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [cobrancaAutomatica, setCobrancaAutomatica] = useState("0");
  const [euroGarantidora, setEuroGarantidora] = useState("0");
  // Novos campos para notificações
  // SSI 125 - GCOLLE - 28/02/2025
  // INICIO
  const [tipoCobranca, setTipoCobranca] = useState<
    "PADRAO" | "PERSONALIZADA" | ""
  >("");

  const [regras, setRegras] = useState<
    {
      dias_apos_vencimento: string;
      meio_notificacao: string;
      id_destinatario: string;
    }[]
  >([]);

  // SSI 0136 - DEBOSSAN - 25/03/25
  // INICIO
  // Implementação dos estados para gerenciamento de cidades e estados
  const [cidades, setCidades] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [estados, setEstados] = useState<
    { value: string; label: string; sigla: string }[]
  >([]);
  const [cidadesSede, setCidadesSede] = useState<ICidadeSede[]>([]);
  const [selectedCidadeSede, setSelectedCidadeSede] =
    useState<ICidadeSede | null>(null);
  const [isAdvogadoExterno, setIsAdvogadoExterno] = useState(false);

  const [modalCidadesSedeOpen, setModalCidadesSedeOpen] = useState(false);
  const [novaCidadeSede, setNovaCidadeSede] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState("");
  const [modalAdicionarCidadeSedeOpen, setModalAdicionarCidadeSedeOpen] =
    useState(false);
  const [cidadesModal, setCidadesModal] = useState<
    { value: string; label: string }[]
  >([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [cidadeToDelete, setCidadeToDelete] = useState<ICidadeSede | null>(
    null,
  );
  // FIM

  // SSI 0136 - DEBOSSAN - 25/03/25
  // INICIO
  // Implementação do useEffect para buscar estados e cidades
  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((estado: { sigla: string; nome: string }) => ({
          value: estado.nome,
          label: estado.nome,
          sigla: estado.sigla,
        }));
        setEstados(options);
      })
      .catch((error) => {
        console.error("Erro ao buscar estados:", error);
      });
  }, []);

  useEffect(() => {
    if (estado) {
      const estadoSelecionado = estados.find((e) => e.value === estado);
      if (estadoSelecionado) {
        fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionado.sigla}/municipios`,
        )
          .then((response) => response.json())
          .then((data) => {
            const options = data.map((cidade: { nome: string }) => ({
              value: cidade.nome,
              label: cidade.nome,
            }));
            setCidades(options);
            // Só limpa a cidade se ela não existir na nova lista de cidades
            if (
              cidade &&
              !options.some(
                (c: { value: string; label: string }) => c.value === cidade,
              )
            ) {
              setCidade("");
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar cidades:", error);
          });
      } else {
        setCidades([]);
        setCidade("");
      }
    } else {
      setCidades([]);
      setCidade("");
    }
  }, [estado]);
  // FIM

  // SSI 0136 - DEBOSSAN - 25/03/25
  // INICIO
  // Implementação do useEffect para buscar cidades quando o estadoSelecionado mudar
  useEffect(() => {
    if (estadoSelecionado) {
      const estadoSelecionadoObj = estados.find(
        (e) => e.value === estadoSelecionado,
      );
      if (estadoSelecionadoObj) {
        fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionadoObj.sigla}/municipios`,
        )
          .then((response) => response.json())
          .then((data) => {
            const options = data.map((cidade: { nome: string }) => ({
              value: cidade.nome,
              label: cidade.nome,
            }));
            setCidadesModal(options);
            setNovaCidadeSede(""); // Limpa a cidade quando mudar o estado
          })
          .catch((error) => {
            console.error("Erro ao buscar cidades:", error);
            setCidadesModal([]);
          });
      } else {
        setCidadesModal([]);
        setNovaCidadeSede("");
      }
    } else {
      setCidadesModal([]);
      setNovaCidadeSede("");
    }
  }, [estadoSelecionado, estados]);
  // FIM

  useEffect(() => {
    if (tipoCobranca === "PERSONALIZADA" && regras.length === 0) {
      setRegras([
        {
          dias_apos_vencimento: "",
          meio_notificacao: "whatsapp",
          id_destinatario: "",
        },
      ]);
    }
  }, [tipoCobranca]);
  // FIM

  const isNovoCondominio = selectedCondominio && !carteiraData?.id_cond;
  const [erroFinanceiro, setErroFinanceiro] = useState("");
  const [erroCobranca, setErroCobranca] = useState("");
  const [erroRH, setErroRH] = useState("");
  const [erroConsultoriaNotificacoes, setErroConsultoriaNotificacoes] =
    useState("");
  const [erroAssembleias, setErroAssembleias] = useState("");
  const [erroCadastro, setErroCadastro] = useState("");
  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  // Busca inicial de condomínios e usuários
  useEffect(() => {
    fetch(`${API_URL}/editar-carteira/buscar-condominios`)
      .then((res) => {
        if (!res.ok) throw new Error("Erro ao buscar condomínios");
        return res.json();
      })
      .then((data) => {
        setCondominios(data);
      })
      .catch((err) => console.error("Erro:", err));

    // Buscar cada área (ajuste conforme o backend espera a string de área)
    getUsuariosByArea("Financeiro").then((data) => setUsuariosFinanceiro(data));
    getUsuariosByArea("Cobrança").then((data) => setUsuariosCobranca(data));
    getUsuariosByArea("RH").then((data) => setUsuariosRH(data));
    getUsuariosByArea("Juridico").then((data) => setUsuariosComunicados(data));
    getUsuariosByArea("Assembleias").then((data) =>
      setUsuariosAssembleias(data),
    );

    // Buscar usuários para "CADASTRO" (caso o backend tenha essa área)
    getUsuariosByArea("Cadastro").then((data) => setUsuariosCadastro(data));

    // Buscar missingCondos
    fetch(`${API_URL}/editar-carteira/buscar-condominios-faltantes`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.missingCondos) {
          setMissingCondosData({
            ...data,
            missingCondos: (data.missingCondos || []).filter(
              (condo: ICondominio) => condo.id_cond > 606,
            ),
          });
        }
      })
      .catch((err) => console.error("Erro missing condos:", err));

    // Buscar cidades sede do banco de dados
    fetch(`${API_URL}/editar-carteira/cidades-sede`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar cidades sede");
        }
        return response.json();
      })
      .then((data) => {
        // Atualizar o estado com as cidades sede
        setCidadesSede(data);
      })
      .catch((error) => {
        console.error("Erro ao carregar cidades sede:", error);
        toast.error("Erro ao carregar cidades sede");
      });
  }, [API_URL]);

  // Função que busca usuários por área
  const getUsuariosByArea = async (area: string): Promise<IUsuario[]> => {
    try {
      const res = await fetch(
        `${API_URL}/editar-carteira/buscar-usuarios-por-area/${area}`,
      );

      if (!res.ok) {
        throw new Error(`Erro ao buscar usuários da área ${area}`);
      }
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("❌ Erro ao buscar usuários:", error);
      return [];
    }
  };

  // useEffect: Detalhes da Carteira (ao selecionar um condomínio)
  useEffect(() => {
    if (!selectedCondominio) return;

    fetch(
      `${API_URL}/editar-carteira/buscar-detalhes-carteira/${selectedCondominio.id_cond}`,
    )
      .then(async (res) => {
        if (!res.ok) {
          const errorData = await res.json();
          if (res.status === 404) {
            throw new Error("Condomínio não encontrado na base de dados");
          }
          throw new Error(
            errorData.error || "Erro ao buscar detalhes da carteira",
          );
        }
        return res.json();
      })
      .then((data) => {
        // console.log("📦 Dados retornados da API:", data);
        // console.log("Debug - id_cidade_sede recebido:", data.id_cidade_sede);

        if (data.error) {
          throw new Error(data.error);
        }

        if (!data || Object.keys(data).length === 0) {
          console.warn("⚠️ Nenhum dado encontrado para esse condomínio!");
          setCarteiraData({});
          toast.warning(
            "Este condomínio ainda não possui uma carteira cadastrada",
          );
          return;
        }

        // Atualiza os dados da carteira primeiro
        setCarteiraData(data);

        // Financeiro
        if (!data.FINANCEIRO || data.statusFinanceiro !== "Ativo") {
          setSelectedFinanceiro(null);
          setErroFinanceiro(
            "O usuário da área Financeiro está inativo ou não definido.",
          );
        } else {
          setSelectedFinanceiro({
            id_func: data.FINANCEIRO,
            nome: data.nomeFinanceiro,
          });
          setErroFinanceiro("");
        }

        // Cobrança
        if (!data.COBRANÇA || data.statusCobranca !== "Ativo") {
          setSelectedCobranca(null);
          setErroCobranca(
            "O usuário da área Cobrança está inativo ou não definido.",
          );
        } else {
          setSelectedCobranca({
            id_func: data.COBRANÇA,
            nome: data.nomeCobranca,
          });
          setErroCobranca("");
        }

        // RH
        if (!data.RH || data.statusRH !== "Ativo") {
          setSelectedRH(null);
          setErroRH("O usuário da área RH está inativo ou não definido.");
        } else {
          setSelectedRH({ id_func: data.RH, nome: data.nomeRH });
          setErroRH("");
        }

        // Comunicações/Notificações
        if (
          !data.COMUNICADOS_NOTIFICAÇÕES ||
          data.statusComunicados !== "Ativo"
        ) {
          setSelectedConsultoriaNotificacoes(null);
          setErroConsultoriaNotificacoes(
            "O usuário da área de Consultoria/Notificações está inativo ou não definido.",
          );
        } else {
          setSelectedConsultoriaNotificacoes({
            id_func: data.COMUNICADOS_NOTIFICAÇÕES,
            nome: data.nomeComunicados,
          });
          setErroConsultoriaNotificacoes("");
        }

        // Assembleias
        if (!data.ASSEMBLEIAS || data.statusAssembleias !== "Ativo") {
          setSelectedAssembleias(null);
          setErroAssembleias(
            "O usuário da área Assembleias está inativo ou não definido.",
          );
        } else {
          setSelectedAssembleias({
            id_func: data.ASSEMBLEIAS,
            nome: data.nomeAssembleias,
          });
          setErroAssembleias("");
        }

        // SSI 125 - GCOLLE - 28/02/2025
        // INICIO
        // Mapeia o tipo de cobrança recebido do backend para o formato esperado pelo frontend
        let tipoCobrancaMapeado: "" | "PADRAO" | "PERSONALIZADA" = "";
        if (
          data.tipoCobranca === "SEM_GARANTIDORA" ||
          data.tipoCobranca === "COM_GARANTIDORA"
        ) {
          tipoCobrancaMapeado = "PADRAO";
        } else if (data.tipoCobranca === "PERSONALIZADA") {
          tipoCobrancaMapeado = "PERSONALIZADA";
        } else if (data.tipoCobranca === "PADRAO") {
          tipoCobrancaMapeado = "PADRAO";
        }
        setTipoCobranca(tipoCobrancaMapeado);

        if (data.tipoCobranca === "PERSONALIZADA" && data.regras) {
          setRegras(data.regras);
        } else {
          setRegras([]);
        }
        // FIM

        // Cadastro
        if (!data.CADASTRO || data.statusCadastro !== "Ativo") {
          setSelectedCadastro(null);
          setErroCadastro(
            "O usuário da área Cadastro está inativo ou não definido.",
          );
        } else {
          setSelectedCadastro({
            id_func: data.CADASTRO,
            nome: data.nomeCadastro,
          });
          setErroCadastro("");
        }

        // Atualiza os campos de cidade e estado
        setCidade(data.CIDADE ?? "");
        setEstado(data.ESTADO ?? "");

        // Atualiza os campos de agendamento e pesos
        setPossuiAgendamento(data.possui_agendamento ? "1" : "0");
        setPesoFinanceiro(data.peso_financeiro?.toString() ?? "");
        setPesoCobranca(data.peso_cobranca?.toString() ?? "");
        setPesoRH(data.peso_rh?.toString() ?? "");
        setPesoConsultoriaNotificacoes(data.peso_comunicados?.toString() ?? "");
        setPesoAssembleias(data.peso_assembleias?.toString() ?? "");
        setPesoCadastro(data.peso_cadastro?.toString() ?? "");

        // Atualiza os campos de configuração
        setIdPholha(data.id_pholha ?? "");
        setDiaPagamentoSalarios(data.dia_util_salario?.toString() ?? "");
        setDiaPagamentoAdiantamento(data.dia_adiantamento?.toString() ?? "");
        setCobrancaAutomatica(data.cobranca_automatica?.toString() ?? "0");
        setEuroGarantidora(data.euro_garantidora?.toString() ?? "0");

        // Atualiza a cidade sede
        // console.log("Debug - cidadesSede disponíveis:", cidadesSede);
        if (data.id_cidade_sede) {
          const cidadeSede = cidadesSede.find(
            (c) => c.id === data.id_cidade_sede,
          );
          if (cidadeSede) {
            setSelectedCidadeSede(cidadeSede);
          }
        }
      })
      .catch((err) => {
        console.error("❌ Erro ao buscar detalhes da carteira:", err);
        toast.error(err.message || "Erro ao carregar os detalhes da carteira");
        resetFields();
      });
  }, [selectedCondominio, API_URL, cidadesSede]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleCondoSelect = (condo: ICondominio | null) => {
    setSelectedCondominio(condo);
    if (condo) {
      setCidade(condo.cidade || "");
      setEstado(condo.uf || "");
    } else {
      // Se o valor for null, limpa os campos relacionados
      setCidade("");
      setEstado("");
      // Se necessário, chame uma função para limpar outros campos:
      resetFields();
    }
  };

  const resetFields = () => {
    setSelectedCondominio(null);
    setSelectedFinanceiro(null);
    setSelectedCobranca(null);
    setSelectedRH(null);
    setSelectedConsultoriaNotificacoes(null);
    setSelectedAssembleias(null);
    setSelectedCadastro(null);

    setCidade("");
    setEstado("");
    setSelectedCidadeSede(null);
    setPossuiAgendamento("");
    setPesoFinanceiro("");
    setPesoCobranca("");
    setPesoRH("");
    setPesoConsultoriaNotificacoes("");
    setPesoAssembleias("");
    setPesoCadastro("");

    setCarteiraData({});
    setIdPholha("");
    setDiaPagamentoSalarios("");
    setDiaPagamentoAdiantamento("");

    // **Resetando os valores dos campos adicionados**
    setCobrancaAutomatica("0");
    setEuroGarantidora("0");

    setErroFinanceiro("");
    setErroCobranca("");
    setErroRH("");
    setErroConsultoriaNotificacoes("");
    setErroAssembleias("");
    setErroCadastro("");
  };

  const handleSalvar = () => {
    if (!selectedCondominio) {
      toast.error("Selecione um condomínio antes de salvar.");
      return;
    }

    // Verificação adicional para os campos obrigatórios
    if (!diaPagamentoSalarios || !diaPagamentoAdiantamento) {
      toast.error(
        "Preencha os campos Dia de Pagamento de Salários e Adiantamento.",
      );
      return;
    }

    // Se Cobrança Automática estiver ativa, obrigue a escolher Padrão ou Personalizada
    if (
      cobrancaAutomatica === "1" &&
      !["PADRAO", "PERSONALIZADA"].includes(tipoCobranca)
    ) {
      toast.error(
        "É obrigatório escolher entre Cobrança Padrão ou Personalizada.",
      );
      return;
    }

    // Validação da cidade sede
    if (!isAdvogadoExterno && !selectedCidadeSede?.id) {
      toast.error("Selecione uma cidade sede.");
      return;
    }

    // Monta objeto principal
    const bodyObj: any = {
      id_cond: selectedCondominio?.id_cond || null,
      CONDOMINIO: selectedCondominio?.nome || "",
      FINANCEIRO: selectedFinanceiro?.id_func || null,
      COBRANÇA: selectedCobranca?.id_func || null,
      RH: selectedRH?.id_func || null,
      COMUNICADOS_NOTIFICAÇÕES: selectedComunicados?.id_func || null,
      ASSEMBLEIAS: selectedAssembleias?.id_func || null,
      CADASTRO: selectedCadastro?.id_func || null,
      CIDADE: cidade,
      ESTADO: estado,
      id_cidade_sede: selectedCidadeSede?.id
        ? Number(selectedCidadeSede.id)
        : null,
      peso_financeiro: pesoFinanceiro || "0",
      peso_cobranca: pesoCobranca || "0",
      peso_rh: pesoRH || "0",
      peso_comunicados: pesoComunicados || "0",
      peso_assembleias: pesoAssembleias || "0",
      peso_cadastro: pesoCadastro || "0",
      possui_agendamento: Number(possuiAgendamento) === 1 ? 1 : 0,
      dia_util_salario: diaPagamentoSalarios,
      cobranca_automatica: Number(cobrancaAutomatica),
      euro_garantidora: Number(euroGarantidora),
      id_pholha: idPholha || null,
      dia_adiantamento: Number(diaPagamentoAdiantamento) || null,

      // SSI 125 - GCOLLE - 28/02/2025
      // Cobranca:
      tipoCobranca, // "PADRAO" ou "PERSONALIZADA"
      ...(tipoCobranca === "PERSONALIZADA" && { regras }),
    };

    // fetch ou axios
    fetch(`${API_URL}/editar-carteira/criar-ou-atualizar-carteira`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(bodyObj),
    })
      .then(async (res) => {
        if (!res.ok) {
          const errorData = await res.json();
          console.error("Debug - Erro da API:", errorData);
          throw new Error(errorData.error || "Erro ao salvar a carteira");
        }

        const responseText = await res.text();

        try {
          return JSON.parse(responseText);
        } catch (e) {
          console.error("Debug - Erro ao fazer parse da resposta:", e);
          throw new Error("Resposta inválida do servidor");
        }
      })
      .then((data) => {
        if (data.success) {
          toast.success("Carteira salva com sucesso!");
          resetFields();
        } else {
          toast.error(data.error || "Erro ao salvar a carteira.");
        }
      })
      .catch((err) => {
        console.error("❌ Erro ao salvar a carteira:", err);
        toast.error(err.message || "Erro de rede ao salvar a carteira.");
      });
  };

  const estadosBrasil = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ];

  // SSI 0117 - PFERRI - Criar rota de integração de condominios e corrigir o cadastro/atualização de carteiras também  - 21/02/2025
  const handleResponsavelChange = (
    setResponsavel: React.Dispatch<React.SetStateAction<IUsuario | null>>,
    setPeso: React.Dispatch<React.SetStateAction<string>>,
    novoValor: IUsuario | null,
  ) => {
    setResponsavel(novoValor);

    if (isNovoCondominio && novoValor) {
      setPeso("1");
    }
  };

  const handleAdicionarCidadeSede = async () => {
    if (novaCidadeSede.trim() && estadoSelecionado) {
      try {
        // Calcula o próximo ID baseado no maior ID existente
        const proximoId =
          Math.max(...cidadesSede.map((cidade) => cidade.id), 0) + 1;

        const response = await fetch(
          `${API_URL}/editar-carteira/cidades-sede`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: proximoId,
              cidade_sede: novaCidadeSede,
              cidade_sede_estado: estadoSelecionado,
            }),
          },
        );

        if (response.ok) {
          setCidadesSede((prev) => [
            ...prev,
            {
              id: proximoId,
              cidade_sede: novaCidadeSede,
              cidade_sede_estado: estadoSelecionado,
            },
          ]);
          toast.success("Cidade sede adicionada com sucesso!");

          // Limpa os estados e fecha os modais
          setNovaCidadeSede("");
          setEstadoSelecionado("");
          setCidadesModal([]);
          setModalAdicionarCidadeSedeOpen(false);
        } else {
          toast.error("Erro ao adicionar cidade sede");
        }
      } catch (error) {
        console.error("Erro ao adicionar cidade sede:", error);
        toast.error("Erro ao adicionar cidade sede");
      }
    }
  };

  const handleRemoverCidadeSede = async (id: number) => {
    try {
      const response = await fetch(
        `${API_URL}/editar-carteira/cidades-sede/${id}`,
        {
          method: "DELETE",
        },
      );

      if (response.ok) {
        setCidadesSede((prev) => prev.filter((cidade) => cidade.id !== id));
        toast.success("Cidade sede removida com sucesso!");
      } else {
        toast.error("Erro ao remover cidade sede");
      }
    } catch (error) {
      console.error("Erro ao remover cidade sede:", error);
      toast.error("Erro ao remover cidade sede");
    }
  };

  // Buscar cidades quando o estadoSelecionado mudar
  useEffect(() => {
    if (estadoSelecionado) {
      const estadoSelecionadoObj = estados.find(
        (e) => e.value === estadoSelecionado,
      );
      if (estadoSelecionadoObj) {
        fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionadoObj.sigla}/municipios`,
        )
          .then((response) => response.json())
          .then((data) => {
            const options = data.map((cidade: { nome: string }) => ({
              value: cidade.nome,
              label: cidade.nome,
            }));
            setCidadesModal(options);
            setNovaCidadeSede(""); // Limpa a cidade quando mudar o estado
          })
          .catch((error) => {
            console.error("Erro ao buscar cidades:", error);
            setCidadesModal([]);
          });
      } else {
        setCidadesModal([]);
        setNovaCidadeSede("");
      }
    } else {
      setCidadesModal([]);
      setNovaCidadeSede("");
    }
  }, [estadoSelecionado, estados]);

  const handleShowConfirmDelete = (cidade: ICidadeSede) => {
    setCidadeToDelete(cidade);
    setShowConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false);
    setCidadeToDelete(null);
  };

  const handleDeleteCidadeSede = async () => {
    if (!cidadeToDelete) return;

    try {
      const response = await fetch(
        `${API_URL}/editar-carteira/cidades-sede/${cidadeToDelete.id}`,
        {
          method: "DELETE",
        },
      );

      if (response.ok) {
        setCidadesSede((prev) =>
          prev.filter((cidade) => cidade.id !== cidadeToDelete.id),
        );
        toast.success("Cidade sede removida com sucesso!");
        handleCloseConfirmDelete();
      } else {
        toast.error("Erro ao remover cidade sede");
      }
    } catch (error) {
      console.error("Erro ao remover cidade sede:", error);
      toast.error("Erro ao remover cidade sede");
    }
  };

  return (
    <div>
      <style>{modalStyles}</style>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="mb-0">Editar Carteira</h2>
        <Button
          variant="primary"
          className="px-5 px-sm-15"
          onClick={() => setModalCidadesSedeOpen(true)}
        >
          Editar Cidades Sedes
        </Button>
      </div>

      <Row>
        {/* Coluna principal (campos de condomínio) */}
        <Col xs={12} xl={7}>
          <Row as="form" className="g-3 mb-6">
            {/* 1) BOTÃO PENDÊNCIAS */}
            <Col xs={12}>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    cursor:
                      missingCondosData?.missingCondos?.length > 0
                        ? "pointer"
                        : "default",
                    visibility:
                      missingCondosData?.missingCondos?.length > 0
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={
                    missingCondosData?.missingCondos?.length > 0
                      ? handleModalOpen
                      : undefined
                  }
                  className="badge badge-phoenix badge-phoenix-warning fs-12 d-inline-flex align-items-center py-2 px-3"
                >
                  🚨 {missingCondosData.missingCondos.length} cadastros
                  pendentes
                </div>
              </div>
            </Col>
            {/* 2) SELECIONAR CONDOMÍNIO */}
            <Col xs={12}>
              <Form.Label>Selecionar um Condomínio</Form.Label>
              <ReactSelect
                placeholder="Selecione o condomínio"
                options={condominios.map((cond) => ({
                  value: cond,
                  label: `${cond.nome} - ${cond.id_cond}`,
                }))}
                value={
                  selectedCondominio
                    ? {
                        value: selectedCondominio,
                        label: `${selectedCondominio.nome} - ${selectedCondominio.id_cond}`,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<ICondominio> | null;
                  handleCondoSelect(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
                isClearable
              />
            </Col>
            {/* 3) FINANCEIRO, AGENDAMENTO, PESO FINANCEIRO */}
            <Col sm={6} md={4}>
              <Form.Label>Responsável Financeiro</Form.Label>
              <ReactSelect
                placeholder="Selecione Financeiro"
                options={usuariosFinanceiro.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedFinanceiro
                    ? {
                        value: selectedFinanceiro,
                        label: selectedFinanceiro.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedFinanceiro(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
              {erroFinanceiro && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroFinanceiro}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Possui Agendamento?</Form.Label>
              <Form.Select
                value={possuiAgendamento}
                onChange={(e) => setPossuiAgendamento(e.target.value)}
              >
                <option value="">Selecione...</option>
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </Form.Select>
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Financeiro</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoFinanceiro}
                onChange={(e) => setPesoFinanceiro(e.target.value)}
              />
            </Col>
            {/* 4) COBRANÇA, PESO COBRANÇA, RH */}
            <Col sm={6} md={8}>
              <Form.Label>Responsável Cobrança</Form.Label>
              <ReactSelect
                placeholder="Selecione Cobrança"
                options={usuariosCobranca.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedCobranca
                    ? { value: selectedCobranca, label: selectedCobranca.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  handleResponsavelChange(
                    setSelectedCobranca,
                    setPesoCobranca,
                    option ? option.value : null,
                  );
                  // Atualiza o estado isAdvogadoExterno
                  setIsAdvogadoExterno(
                    option?.value?.advogado_externo === "Sim" || false,
                  );
                }}
                closeMenuOnSelect={true}
              />
              {erroCobranca && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroCobranca}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Cobrança</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoCobranca}
                onChange={(e) => setPesoCobranca(e.target.value)}
              />
            </Col>
            <Col sm={6} md={8}>
              <Form.Label>Responsável RH</Form.Label>
              <ReactSelect
                placeholder="Selecione RH"
                options={usuariosRH.map((u) => ({ value: u, label: u.nome }))}
                value={
                  selectedRH
                    ? { value: selectedRH, label: selectedRH.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  handleResponsavelChange(
                    setSelectedRH,
                    setPesoRH,
                    option ? option.value : null,
                  );
                }}
                closeMenuOnSelect={true}
              />
              {erroRH && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroRH}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso RH</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoRH}
                onChange={(e) => setPesoRH(e.target.value)}
              />
            </Col>

            <Col sm={6} md={8}>
              <Form.Label>Responsável Consultoria/Notificações</Form.Label>
              <ReactSelect
                placeholder="Selecione Consultoria/Notificações"
                options={usuariosComunicados.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedComunicados
                    ? {
                        value: selectedComunicados,
                        label: selectedComunicados.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  handleResponsavelChange(
                    setSelectedConsultoriaNotificacoes,
                    setPesoConsultoriaNotificacoes,
                    option ? option.value : null,
                  );
                }}
                closeMenuOnSelect={true}
              />{" "}
              {erroConsultoriaNotificacoes && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroConsultoriaNotificacoes}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Consultoria/Notificações</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoComunicados}
                onChange={(e) => setPesoConsultoriaNotificacoes(e.target.value)}
              />
            </Col>
            <Col sm={6} md={8}>
              <Form.Label>Responsável Assembleias</Form.Label>
              <ReactSelect
                placeholder="Selecione Assembleias"
                options={usuariosAssembleias.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedAssembleias
                    ? {
                        value: selectedAssembleias,
                        label: selectedAssembleias.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  handleResponsavelChange(
                    setSelectedAssembleias,
                    setPesoAssembleias,
                    option ? option.value : null,
                  );
                }}
                closeMenuOnSelect={true}
              />
              {erroAssembleias && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroAssembleias}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Assembleias</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoAssembleias}
                onChange={(e) => setPesoAssembleias(e.target.value)}
              />
            </Col>
            <Col sm={6} md={8}>
              <Form.Label>Responsável Cadastro</Form.Label>
              <ReactSelect
                placeholder="Selecione Cadastro"
                options={usuariosCadastro.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedCadastro
                    ? { value: selectedCadastro, label: selectedCadastro.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  handleResponsavelChange(
                    setSelectedCadastro,
                    setPesoCadastro,
                    option ? option.value : null,
                  );
                }}
                closeMenuOnSelect={true}
              />{" "}
              {erroCadastro && (
                <div className="d-flex align-items-center mt-1 gap-2">
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  <span
                    className="text-danger ml-2"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {erroCadastro}
                  </span>
                </div>
              )}
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Cadastro</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoCadastro}
                onChange={(e) => setPesoCadastro(e.target.value)}
              />
            </Col>
            {/* 7) ESTADO, CIDADE E CIDADE SEDE */}
            <Col sm={4} md={4}>
              <Form.Label>Estado</Form.Label>
              <ReactSelect
                placeholder="Selecione o Estado"
                options={estados}
                value={
                  estado
                    ? {
                        value: estado,
                        label: estados.find((e) => e.value === estado)?.label,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<string> | null;
                  setEstado(option ? option.value : "");
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={4} md={4}>
              <Form.Label>Cidade</Form.Label>
              <ReactSelect
                placeholder="Selecione a Cidade"
                options={cidades}
                value={cidade ? { value: cidade, label: cidade } : null}
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<string> | null;
                  setCidade(option ? option.value : "");
                }}
                closeMenuOnSelect={true}
                isDisabled={!estado}
              />
            </Col>
            <Col sm={4} md={4}>
              <Form.Label>
                Cidade referente a:{" "}
                {!isAdvogadoExterno && <span className="text-danger">*</span>}
              </Form.Label>
              <ReactSelect
                placeholder="Selecione a Cidade Sede"
                options={cidadesSede.map((cidade) => ({
                  value: cidade.id,
                  label: `${cidade.cidade_sede} - ${cidade.cidade_sede_estado}`,
                }))}
                value={
                  selectedCidadeSede
                    ? {
                        value: selectedCidadeSede.id,
                        label: `${selectedCidadeSede.cidade_sede} - ${selectedCidadeSede.cidade_sede_estado}`,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<number> | null;
                  const cidadeSelecionada = cidadesSede.find(
                    (cidade) => cidade.id === option?.value,
                  );
                  setSelectedCidadeSede(cidadeSelecionada || null);
                }}
                closeMenuOnSelect={true}
                isClearable
                required={!isAdvogadoExterno}
              />
            </Col>
            {/* 8) BOTÕES */}
            <Col xs={12} className="gy-6 mt-3">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={resetFields}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSalvar}
                >
                  Salvar
                </Button>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Coluna "Configurações Adicionais" (expand/collapse) */}
        <Col xs={12} xl={5} className="mt-9">
          <Row className="g-2">
            <Col xs={12} xl={12}>
              <Card>
                <Card.Header
                  style={{ cursor: "pointer" }}
                  onClick={toggleVisibility}
                  className="d-flex align-items-center justify-content-between"
                >
                  <h4 className="mb-0">Configurações Adicionais</h4>
                  <FontAwesomeIcon
                    icon={isExpanded ? faAngleUp : faAngleDown}
                    className="fs-9 text-900"
                  />
                </Card.Header>
                {isExpanded && (
                  <Card.Body>
                    <Row className="gx-3 gy-3">
                      {/* Campo: ID Pholha */}
                      <Col xs={12}>
                        <Form.Label>ID Pholha</Form.Label>
                        <Form.Control
                          placeholder="ID Pholha"
                          type="text"
                          value={idPholha}
                          onChange={(e) => setIdPholha(e.target.value)}
                        />
                      </Col>

                      {/* Campo: Dia de Pagamento Salários */}
                      <Col xs={12}>
                        <Form.Label>Dia de Pagamento Salários</Form.Label>
                        <Form.Select
                          value={diaPagamentoSalarios}
                          onChange={(e) =>
                            setDiaPagamentoSalarios(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Selecione...
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Form.Select>
                      </Col>

                      {/* Campo: Dia de Pagamento Adiantamento */}
                      <Col xs={12}>
                        <Form.Label>Dia de Pagamento Adiantamento</Form.Label>
                        <Form.Select
                          value={diaPagamentoAdiantamento}
                          onChange={(e) =>
                            setDiaPagamentoAdiantamento(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Selecione...
                          </option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                        </Form.Select>
                      </Col>

                      {/* SSI 125 - GCOLLE - 28/02/2025 */}
                      {/* INICIO */}

                      {/* Euro Garantidora */}
                      <Col xs={12}>
                        <Form.Label>Euro Garantidora</Form.Label>
                        <Form.Select
                          value={euroGarantidora}
                          onChange={(e) => setEuroGarantidora(e.target.value)}
                        >
                          <option value="0">Não</option>
                          <option value="1">Sim</option>
                        </Form.Select>
                      </Col>
                      {/* Cobrança Automática */}
                      <Col xs={12}>
                        <Form.Label className="me-2">
                          Cobrança Automática
                        </Form.Label>
                        <Col className="d-flex align-items-center">
                          <Form.Select
                            value={cobrancaAutomatica}
                            onChange={(e) =>
                              setCobrancaAutomatica(e.target.value)
                            }
                            style={{ flex: 1 }}
                          >
                            <option value="0">Não</option>
                            <option value="1">Sim</option>
                          </Form.Select>
                        </Col>
                      </Col>

                      {/* SE a cobrança_automatica for "1", mostre a opção Padrão/Personalizada */}
                      {cobrancaAutomatica === "1" && (
                        <>
                          <Col xs={12} className="mt-3">
                            <Form.Group>
                              <Form.Label>Tipo de Cobrança</Form.Label>
                              <div>
                                <Form.Check
                                  inline
                                  label="Padrão"
                                  type="radio"
                                  id="radio-padrao"
                                  value="PADRAO"
                                  checked={tipoCobranca === "PADRAO"}
                                  onChange={(e) =>
                                    setTipoCobranca(
                                      e.target.value as
                                        | "PADRAO"
                                        | "PERSONALIZADA"
                                        | "",
                                    )
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="Personalizada"
                                  type="radio"
                                  id="radio-personalizada"
                                  value="PERSONALIZADA"
                                  checked={tipoCobranca === "PERSONALIZADA"}
                                  onChange={(e) =>
                                    setTipoCobranca(
                                      e.target.value as
                                        | "PADRAO"
                                        | "PERSONALIZADA"
                                        | "",
                                    )
                                  }
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          {tipoCobranca === "PERSONALIZADA" && (
                            <Col xs={11} className="mt-1 mx-2">
                              <Form.Label>Regras de Notificação</Form.Label>

                              {regras.map((regra, index) => (
                                <Row
                                  key={index}
                                  className="g-2 align-items-center mb-2"
                                >
                                  {/* Cobrar após (menor) */}
                                  <Col xs={12} sm={3}>
                                    <Form.Control
                                      type="number"
                                      placeholder="Dia após"
                                      value={regra.dias_apos_vencimento}
                                      onChange={(e) => {
                                        const novasRegras = [...regras];
                                        novasRegras[
                                          index
                                        ].dias_apos_vencimento = e.target.value;
                                        setRegras(novasRegras);
                                      }}
                                    />
                                  </Col>

                                  {/* Meio Notificação (maior) */}
                                  <Col xs={12} sm={4}>
                                    <Form.Select
                                      value={regra.meio_notificacao}
                                      onChange={(e) => {
                                        const novasRegras = [...regras];
                                        novasRegras[index].meio_notificacao =
                                          e.target.value;
                                        setRegras(novasRegras);
                                      }}
                                    >
                                      <option value="whatsapp">WhatsApp</option>
                                      <option value="email">Email</option>
                                    </Form.Select>
                                  </Col>

                                  {/* Destinatário (maior) */}
                                  <Col xs={12} sm={4}>
                                    <Form.Select
                                      value={regra.id_destinatario || "1"}
                                      onChange={(e) => {
                                        const novasRegras = [...regras];
                                        novasRegras[index].id_destinatario =
                                          e.target.value;
                                        setRegras(novasRegras);
                                      }}
                                    >
                                      <option value="1">Qualquer</option>
                                      <option value="2">
                                        Somente Proprietário
                                      </option>
                                      <option value="3">
                                        Somente Inquilino
                                      </option>
                                    </Form.Select>
                                  </Col>

                                  {/* Botão de Remoção */}
                                  {regras.length > 1 && (
                                    <Col xs={12} sm={1} className="text-end">
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => {
                                          const novasRegras = regras.filter(
                                            (_, i) => i !== index,
                                          );
                                          setRegras(novasRegras);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              ))}

                              {/* Botão "Adicionar Regra" */}
                              <div className="mt-1">
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setRegras([
                                      ...regras,
                                      {
                                        dias_apos_vencimento: "",
                                        meio_notificacao: "whatsapp",
                                        id_destinatario: "1",
                                      },
                                    ])
                                  }
                                >
                                  Adicionar Regra
                                </Button>
                              </div>
                            </Col>
                          )}
                        </>
                      )}
                      {/* FIM */}
                    </Row>
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Modal dos condomínios pendentes */}
      <Modal show={modalOpen} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Condomínios Pendentes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          {missingCondosData.missingCondos.length > 0 ? (
            <ListGroup>
              {missingCondosData.missingCondos.map((condo) => (
                <ListGroup.Item
                  key={condo.id_cond}
                  action
                  onClick={() => {
                    handleCondoSelect(condo);
                    handleModalClose();
                  }}
                >
                  {condo.nome} - (ID: {condo.id_cond})
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>Nenhum condomínio pendente encontrado.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* SSI 0136 - DEBOSSAN - 26/03/2025 
      INICIO
      */}
      {/* Modal de Edição de Cidades Sedes */}
      <Modal
        show={modalCidadesSedeOpen}
        onHide={() => {
          setModalCidadesSedeOpen(false);
          setModalAdicionarCidadeSedeOpen(false);
        }}
        centered
        size="lg"
        backdrop={
          modalAdicionarCidadeSedeOpen || showConfirmDelete ? "static" : true
        }
        className={
          modalAdicionarCidadeSedeOpen || showConfirmDelete ? "modal-blur" : ""
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Cidades Sedes</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "60vh", overflowY: "auto", padding: "1rem" }}
        >
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Estado</th>
                  <th>Cidade</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {cidadesSede.map((cidade) => (
                  <tr key={cidade.id} className="cidade-table-row">
                    <td>{cidade.id}</td>
                    <td>{cidade.cidade_sede_estado}</td>
                    <td>{cidade.cidade_sede}</td>
                    <td
                      style={{ width: "80px", padding: 0, textAlign: "center" }}
                    >
                      <button
                        type="button"
                        title="Deletar"
                        className="btn-sm text-danger p-0 m-0 icon-button btn btn-link"
                        onClick={() => handleShowConfirmDelete(cidade)}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                        >
                          <g>
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </g>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalCidadesSedeOpen(false)}
          >
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => setModalAdicionarCidadeSedeOpen(true)}
          >
            Adicionar Cidade
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Adicionar Cidade Sede */}
      <Modal
        show={modalAdicionarCidadeSedeOpen}
        onHide={() => {
          setModalAdicionarCidadeSedeOpen(false);
          setNovaCidadeSede("");
          setEstadoSelecionado("");
          setCidadesModal([]);
        }}
        centered
        backdrop="static"
        className=""
        style={{ zIndex: 1060 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Nova Cidade Sede</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1rem" }}>
          <Row className="g-3">
            <Col xs={12}>
              <Form.Label>Estado</Form.Label>
              <ReactSelect
                placeholder="Selecione o Estado"
                options={estados}
                value={
                  estadoSelecionado
                    ? { value: estadoSelecionado, label: estadoSelecionado }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<string> | null;
                  setEstadoSelecionado(option ? option.value : "");
                  setNovaCidadeSede(""); // Limpa a cidade quando mudar o estado
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col xs={12}>
              <Form.Label>Cidade</Form.Label>
              <ReactSelect
                placeholder="Selecione a Cidade"
                options={cidadesModal}
                value={
                  novaCidadeSede
                    ? { value: novaCidadeSede, label: novaCidadeSede }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<string> | null;
                  setNovaCidadeSede(option ? option.value : "");
                }}
                closeMenuOnSelect={true}
                isDisabled={!estadoSelecionado}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalAdicionarCidadeSedeOpen(false);
              setNovaCidadeSede("");
              setEstadoSelecionado("");
              setCidadesModal([]);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleAdicionarCidadeSede}
            disabled={!novaCidadeSede.trim() || !estadoSelecionado}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Confirmação de Exclusão */}
      <Modal
        show={showConfirmDelete}
        onHide={handleCloseConfirmDelete}
        centered
        backdrop="static"
        className=""
        style={{ zIndex: 1060 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1rem" }}>
          Tem certeza que deseja{" "}
          <strong style={{ color: "#ef4444" }}>DELETAR</strong> a cidade sede{" "}
          <strong>{cidadeToDelete?.cidade_sede}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteCidadeSede}>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* FIM */}
    </div>
  );
};

export default EditarCarteira;
