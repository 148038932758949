/* //SSI 0141 - PFERRI - Adicionar tabela de condominios dos usuários e melhorias visuais - 27/03/2025 */
import React from "react";
import Select, { components, MultiValueGenericProps } from "react-select";
import { Checkbox } from "@mui/material";

// Custom Option para mostrar o checkbox
const CustomOption = (props: any) => {
  const { data, isSelected, innerRef, innerProps } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", padding: "8px" }}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => {}}
        style={{ padding: 0, marginRight: 8 }}
      />
      {data.label}
    </div>
  );
};

// Custom MultiValue para não exibir o valor repetido no campo
const CustomMultiValue = (props: MultiValueGenericProps<any>) => null;

interface SelectCheckboxProps {
  options: { value: string; label: string }[];
  value: { value: string; label: string }[];
  onChange: (selected: { value: string; label: string }[]) => void;
  placeholder?: string;
}

const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  options,
  value,
  onChange,
  placeholder = "Selecione...",
}) => {
  return (
    <Select
      isMulti
      options={options}
      value={value}
      onChange={(selected) => onChange(selected as any)}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option: CustomOption,
        MultiValue: CustomMultiValue,
      }}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: "39px",
          height: "38px",
          minWidth: "250px",
          border: "1px solid var(--phoenix-input-border-color) !important",
        }),
        valueContainer: (base) => ({
          ...base,
          padding: "2px 8px",
        }),
        placeholder: (base) => ({
          ...base,
          marginLeft: "2px",
        }),
      }}
    />
  );
};

export default SelectCheckbox;
