//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  Calendar,
} from "feather-icons-react";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import SearchBox from "components/common/SearchBox";
import axios from "axios";

const VisaoGeralTable: React.FC = () => {
  // VALIDAÇÕES DE MÊS/ANO (SIMPLIFICADAS)
  const setMesSeguro = (novoMes: number) => {
    if (novoMes < 1) {
      setMes(12);
    } else if (novoMes > 12) {
      setMes(1);
    } else {
      setMes(novoMes);
    }
  };

  const setAnoSeguro = (novoAno: number) => {
    if (novoAno < 1900) {
      setAno(1900);
    } else if (novoAno > 2100) {
      setAno(2100);
    } else {
      setAno(novoAno);
    }
  };

  // STATES
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [dados, setDados] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // FUNÇÕES DE APOIO
  /**
   * Converte uma string como "1/26" em { num: 1, den: 26 }
   * Caso a string seja inválida, retorna { num: 0, den: 0 }
   */
  const parseFraction = (value: string) => {
    if (!value) return { num: 0, den: 0 };
    const [num, den] = value.split("/");
    return {
      num: parseInt(num, 10) || 0,
      den: parseInt(den, 10) || 0,
    };
  };

  /**
   * Para uma coluna específica (ex: "reinf_enviado"),
   * soma todos os numeradores e denominadores dos itens de 'dados'.
   */
  const getColumnTotals = (key: string) => {
    let sumNum = 0;
    let sumDen = 0;
    dados.forEach((item) => {
      const { num, den } = parseFraction(item[key]);
      sumNum += num;
      sumDen += den;
    });
    return { sumNum, sumDen };
  };

  /**
   * Retorna o texto do cabeçalho da coluna com N/Total,
   * onde N é a soma dos numeradores e Total é a soma dos denominadores.
   */
  const renderColumnHeader = (titulo: string, key: string) => {
    const { sumNum, sumDen } = getColumnTotals(key);
    return (
      <div style={{ textAlign: "center" }}>
        <div>{titulo}</div>
        <div style={{ fontSize: "12px", color: "gray" }}>
          {sumNum}/{sumDen}
        </div>
      </div>
    );
  };

  // CONFIGURANDO A TABELA
  const table = useAdvanceTable({
    data: dados,
    columns: [
      {
        accessorKey: "colaborador",
        header: "Colaborador",
      },
      {
        accessorKey: "reinf_enviado",
        header: () => renderColumnHeader("ReInf", "reinf_enviado"),
      },
      {
        accessorKey: "balancetes_enviado",
        header: () => renderColumnHeader("Balancetes", "balancetes_enviado"),
      },
      {
        accessorKey: "leitura_enviado",
        header: () => renderColumnHeader("Leitura", "leitura_enviado"),
      },
      {
        accessorKey: "consumos_individuais_enviado",
        header: () =>
          renderColumnHeader(
            "Consumos Individuais",
            "consumos_individuais_enviado",
          ),
      },
      {
        accessorKey: "reservas_enviado",
        header: () => renderColumnHeader("Reservas", "reservas_enviado"),
      },
      {
        accessorKey: "boleto_enviado",
        header: () => renderColumnHeader("Boleto", "boleto_enviado"),
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
  });

  // BUSCA DE DADOS
  const fetchDados = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/financeiro/visaogeral`,
        { params: { mes, ano } },
      );

      // Filtra quem não tem valor relevante ("0/0" em todas as colunas)
      const filteredData = response.data.fechamento_condominios.filter(
        (item: any) => {
          const semDados =
            item.reinf_enviado === "0/0" &&
            item.leitura_enviado === "0/0" &&
            item.consumos_individuais_enviado === "0/0" &&
            item.reservas_enviado === "0/0" &&
            item.balancetes_enviado === "0/0" &&
            item.boleto_enviado === "0/0";
          return !semDados;
        },
      );

      setDados(filteredData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  // HOOKS
  useEffect(() => {
    fetchDados();
  }, [mes, ano]);

  // FILTRO GLOBAL
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  // FORMATANDO O PERÍODO
  const formatPeriodoExtenso = () => {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    return `${meses[mes - 1]} ${ano}`;
  };

  // POPOVER (MÊS/ANO)
  const popover = (
    <Popover id="popover-calendar">
      <Popover.Body>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "220px", gap: "10px" }}
        >
          <select
            value={mes}
            onChange={(e) => setMesSeguro(Number(e.target.value))}
            className="form-select"
            style={{ width: "120px", textAlign: "center" }}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
              <option key={m} value={m}>
                {
                  [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro",
                  ][m - 1]
                }
              </option>
            ))}
          </select>
          <select
            value={ano}
            onChange={(e) => setAnoSeguro(Number(e.target.value))}
            className="form-select"
            style={{ width: "100px", textAlign: "center" }}
          >
            {Array.from(
              { length: 10 },
              (_, i) => new Date().getFullYear() - i,
            ).map((a) => (
              <option key={a} value={a}>
                {a}
              </option>
            ))}
          </select>
        </div>
      </Popover.Body>
    </Popover>
  );
  /* //SSI 0141 - PFERRI - Corrigindo alteração de mês - 28/03/2025 */
  const handleMesAnterior = () => {
    setMes((prevMes) => {
      if (prevMes === 1) {
        setAno((prevAno) => prevAno - 1);
        return 12;
      }
      return prevMes - 1;
    });
  };

  const handleMesPosterior = () => {
    setMes((prevMes) => {
      if (prevMes === 12) {
        setAno((prevAno) => prevAno + 1);
        return 1;
      }
      return prevMes + 1;
    });
  };
  {
    /* TABELA /* //SSI 0141 - PFERRI - Cabeçalho fixo nas tabelas - 28/03/2025 */
  }
  // RENDER
  return (
    <div>
      <style>
        {`
        .sticky-header thead th {
          position: sticky;
          top: 0;
          z-index: 10;
          background: #fff;
        }

        .scroll-wrapper {
          max-height: 500px;
          overflow-y: auto;
          overflow-x: auto;
        }

        .scroll-wrapper table {
          width: 100%;
          border-collapse: collapse;
        }
      `}
      </style>
      <AdvanceTableProvider {...table}>
        <Row className="g-4 align-items-center mb-4">
          <Col xs={12} sm={3} md={3}>
            <Form.Group controlId="buscar">
              <Form.Label>Buscar Colaborador</Form.Label>
              <SearchBox
                placeholder="Pesquisar Colaborador"
                onChange={handleSearchInputChange}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>
          {/* /* //SSI 0141 - PFERRI - Corrigindo alteração de mês - 28/03/2025 */}
          <Col xs={12} sm={3} md={3} className="ms-auto">
            <Form.Group controlId="periodoExtenso">
              <Form.Label>Selecionar Período</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChevronsLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  onClick={() => setAno((prev) => prev - 1)}
                />
                <ChevronLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "8px" }}
                  onClick={() => {
                    if (mes === 1) {
                      setAno((prev) => prev - 1);
                      setMes(12);
                    } else {
                      setMes((prev) => prev - 1);
                    }
                  }}
                />
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{
                      position: "relative",
                      flexGrow: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Form.Control
                      readOnly
                      placeholder="Selecionar período"
                      style={{
                        border: "1px solid #d3d3d3",
                        borderRadius: "6px",
                        paddingLeft: "40px",
                      }}
                      value={formatPeriodoExtenso()}
                    />
                    <Calendar
                      size={16}
                      style={{
                        color: "gray",
                        position: "absolute",
                        left: "14px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </OverlayTrigger>
                <ChevronRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={() => {
                    if (mes === 12) {
                      setAno((prev) => prev + 1);
                      setMes(1);
                    } else {
                      setMes((prev) => prev + 1);
                    }
                  }}
                />
                <ChevronsRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                  onClick={() => setAno((prev) => prev + 1)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {/* Spinner de carregamento */}
        {loading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {/* TABELA /* //SSI 0141 - PFERRI - Cabeçalho fixo nas tabelas - 28/03/2025 */}
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
              <div
                className="tabela-scroll-wrapper"
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                <AdvanceTable
                  tableProps={{
                    className:
                      "phoenix-table fs-9 table-hover text-center align-left sticky-header",
                  }}
                />
              </div>
            </div>

            <AdvanceTableFooter pagination />

            {/* Se não houver dados após a busca */}
            {dados.length === 0 && !loading && (
              <div className="text-center mt-3">
                Nenhum dado encontrado para o período selecionado.
              </div>
            )}
          </>
        )}
      </AdvanceTableProvider>
    </div>
  );
};

export default VisaoGeralTable;
