//SSI 0150 - PFERRI - Correção em fechamento mensal- implementando a tabela visao geral dentro da analise de feechamentos dos condominios- 01/04/2025
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import SearchBox from "components/common/SearchBox";
import axios from "axios";

interface VisaoGeralTableProps {
  mes: number;
  ano: number;
  colaboradoresPermitidos: string[];
}

const TabelaVisaoGeral: React.FC<VisaoGeralTableProps> = ({
  mes,
  ano,
  colaboradoresPermitidos,
}) => {
  const [dados, setDados] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const parseFraction = (value: string) => {
    if (!value) return { num: 0, den: 0 };
    const [num, den] = value.split("/");
    return {
      num: parseInt(num, 10) || 0,
      den: parseInt(den, 10) || 0,
    };
  };

  const getColumnTotals = (key: string) => {
    let sumNum = 0;
    let sumDen = 0;
    dados.forEach((item) => {
      const { num, den } = parseFraction(item[key]);
      sumNum += num;
      sumDen += den;
    });
    return { sumNum, sumDen };
  };

  const renderColumnHeader = (titulo: string, key: string) => {
    const { sumNum, sumDen } = getColumnTotals(key);
    return (
      <div style={{ textAlign: "center" }}>
        <div>{titulo}</div>
        <div style={{ fontSize: "12px", color: "gray" }}>
          {sumNum}/{sumDen}
        </div>
      </div>
    );
  };

  const table = useAdvanceTable({
    data: dados,
    columns: [
      { accessorKey: "colaborador", header: "Colaborador" },
      {
        accessorKey: "reinf_enviado",
        header: () => renderColumnHeader("ReInf", "reinf_enviado"),
      },
      {
        accessorKey: "balancetes_enviado",
        header: () => renderColumnHeader("Balancetes", "balancetes_enviado"),
      },
      {
        accessorKey: "leitura_enviado",
        header: () => renderColumnHeader("Leitura", "leitura_enviado"),
      },
      {
        accessorKey: "consumos_individuais_enviado",
        header: () =>
          renderColumnHeader("Consumos", "consumos_individuais_enviado"),
      },
      {
        accessorKey: "reservas_enviado",
        header: () => renderColumnHeader("Reservas", "reservas_enviado"),
      },
      {
        accessorKey: "boleto_enviado",
        header: () => renderColumnHeader("Boletos", "boleto_enviado"),
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
  });

  const fetchDados = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/financeiro/visaogeral`,
        { params: { mes, ano } },
      );
      const filteredData = response.data.fechamento_condominios
        .filter((item: any) =>
          colaboradoresPermitidos.includes(item.colaborador),
        )
        .filter((item: any) => {
          return !(
            item.reinf_enviado === "0/0" &&
            item.leitura_enviado === "0/0" &&
            item.consumos_individuais_enviado === "0/0" &&
            item.reservas_enviado === "0/0" &&
            item.balancetes_enviado === "0/0" &&
            item.boleto_enviado === "0/0"
          );
        })
        .sort((a: any, b: any) =>
          a.colaborador.localeCompare(b.colaborador, "pt-BR", {
            sensitivity: "base",
          }),
        );

      setDados(filteredData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDados();
  }, [mes, ano]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mb-3" style={{ maxWidth: "300px" }}>
          <SearchBox
            placeholder="Pesquisar Colaborador"
            onChange={handleSearchInputChange}
            style={{ width: "100%" }}
          />
        </div>

        {loading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div
              className="card shadow-sm rounded-3 p-3 w-48 mb-3"
              style={{
                display: "flex",
                width: "100% ",
                height: "350px",
                flexDirection: "column",
                justifyContent: "space-between",
                overflowX: "auto",
              }}
            >
              <AdvanceTable
                tableProps={{
                  className:
                    "phoenix-table fs-9 table-hover text-center align-left sticky-header",
                }}
              />
            </div>
            <AdvanceTableFooter pagination />
            {dados.length === 0 && !loading && (
              <div className="text-center mt-3">
                Nenhum dado encontrado para o período selecionado.
              </div>
            )}
          </>
        )}
      </AdvanceTableProvider>
    </div>
  );
};

export default TabelaVisaoGeral;
