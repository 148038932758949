/* //SSI 0115 - PFERRI - Ajustes no layout e melhorias - 18/02/2025 */
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Card, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { UilBuilding, UilUser, UilCalendarAlt } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import Stat from "./components/Stat";
import GraficoCondominios from "./components/GraficoCondominios";
import CondosPorCidade from "./components/CondosPorCidade";
import GraficoAssembleias from "./components/GraficoAssembleias";
import { ProjectManagementStat } from "./components/stats";

const colors = ["#238ee6", "#38c172", "#f6c23e", "#fd7e14"];
const API_URL = process.env.REACT_APP_API_URL;
const WEB_API = process.env.WEB_API;

const DashboardProject: React.FC = () => {
  const [stats, setStats] = useState<ProjectManagementStat[]>([]);
  const [eventos, setEventos] = useState<any[]>([]);
  const [atualizacoes, setAtualizacoes] = useState<any[]>([]);
  const [carregando, setCarregando] = useState(false);
  const [view, setView] = useState<"eventos" | "atualizacoes">("eventos");
  const [rotationAngle, setRotationAngle] = useState(0);

  const [podeVisualizarDashboard, setPodeVisualizarDashboard] = useState(true);
  const [nomeUsuario, setNomeUsuario] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Tenta pegar o user do localStorage
    const userString = localStorage.getItem("user");
    if (userString) {
      const userObject = JSON.parse(userString);
      // Seta o nome para exibir depois
      setNomeUsuario(userObject.nome);

      // Caso ele seja do tipo cidade ou advogado_externo, ocultar Dashboard
      if (
        userObject.usuario_cidade === "Sim" ||
        userObject.advogado_externo === "Sim"
      ) {
        setPodeVisualizarDashboard(false);
        return; // encerra a função para não buscar dados
      }
    }

    // Se pode ver o dashboard, faz as chamadas de API
    fetchStats();
    fetchEventos();
    fetchAtualizacoes();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await fetch(`${API_URL}/dados-dashboard/dashboard`);
      if (!response.ok) throw new Error("Erro na resposta da API");

      const data = await response.json();
      setStats([
        {
          title: "Condomínios",
          count: data.carteiras,
          subtitle: "Condomínios ativos",
          icon: UilBuilding,
          iconColor: "#238ee6",
        },
        {
          title: "Usuários",
          count: data.usuarios,
          subtitle: "Usuários ativos",
          icon: UilUser,
          iconColor: "#38c172",
        },
        {
          title: "Assembleias",
          count: data.assembleias,
          subtitle: "Assembleias futuras",
          icon: UilCalendarAlt,
          iconColor: "#f6c23e",
        },
      ]);
    } catch (error) {
      console.error("Erro ao buscar os dados do dashboard:", error);
    }
  };

  const fetchEventos = async () => {
    try {
      setCarregando(true);
      const response = await fetch(`${API_URL}/eventos/buscar?page=1&limit=3`);
      const data = await response.json();
      setEventos(data.eventos || []);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    } finally {
      setCarregando(false);
    }
  };

  const fetchAtualizacoes = async () => {
    try {
      setCarregando(true);
      const response = await fetch(
        `${API_URL}/atualizacoes/buscar-atualizacoes`,
      );
      const data = await response.json();
      setAtualizacoes(
        data.map((item: any) => ({
          id: item.id,
          versao: item.versao,
          titulo: item.titulo,
          criadoEm: new Date(item.criado_em).toLocaleDateString("pt-BR"),
        })),
      );
    } catch (error) {
      console.error("Erro ao buscar atualizações:", error);
    } finally {
      setCarregando(false);
    }
  };

  const toggleView = () => {
    setRotationAngle((prev) => prev + 180);
    setView((prev) => (prev === "eventos" ? "atualizacoes" : "eventos"));
  };

  const handleVerMaisClick = () => {
    if (view === "eventos") {
      navigate(`/pages/atualizacoes/listar-eventos`);
    } else if (view === "atualizacoes") {
      navigate(`/pages/atualizacoes/atualizacoes-eureka`);
    }
  };

  const renderCards = () => {
    const data = view === "eventos" ? eventos : atualizacoes;

    if (carregando) return <Spinner animation="border" />;

    if (data.length === 0)
      return (
        <p className="text-center">
          Nenhum {view === "eventos" ? "evento" : "atualização"} encontrado.
        </p>
      );

    return (
      <Card
        className="shadow-sm"
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Card.Header
          className="p-2 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#ffffff",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <h5
            className="mb-0"
            style={{
              fontSize: "0.9rem",
              color: view === "eventos" ? "#238ee6" : "#198754",
            }}
          >
            {view === "eventos" ? "Eventos Recentes" : "Atualizações Recentes"}
          </h5>
          <div
            onClick={toggleView}
            style={{
              cursor: "pointer",
              transition: "transform 0.5s ease-in-out",
              transform: `rotate(${rotationAngle}deg)`,
              color: view === "eventos" ? "#238ee6" : "#198754",
            }}
          >
            <FeatherIcon icon="refresh-cw" size={18} />
          </div>
        </Card.Header>
        <Card.Body
          className="p-1"
          style={{
            maxHeight: "10rem",
            overflowY: "auto",
            backgroundColor: "#f8f9fa",
          }}
        >
          {data.slice(0, 3).map((item, idx) => (
            <Card
              key={idx}
              className="mb-3"
              style={{
                backgroundColor: "#fcfcfc",
                border: "1px solid #ddd",
                borderRadius: "6px",
                margin: "2px",
              }}
            >
              <Card.Body className="d-flex justify-content-between align-items-center p-2">
                <Card.Title
                  className="mb-0"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: view === "eventos" ? "#238ee6" : "#198754",
                  }}
                >
                  {item.versao} {item.titulo}
                </Card.Title>
                <Button
                  variant="link"
                  onClick={() =>
                    console.log(`Redirecionando para ${item.titulo}`)
                  }
                  style={{
                    padding: "0 0 0 5px",
                    fontSize: "0.85rem",
                    textDecoration: "none",
                    color: view === "eventos" ? "#238ee6" : "#198754",
                  }}
                >
                  <FeatherIcon icon="arrow-right" size={16} />
                </Button>
              </Card.Body>
              <Card.Footer
                className="text-muted"
                style={{ fontSize: "0.7rem", padding: "2px 5px" }}
              >
                Criado em:{" "}
                {view === "eventos"
                  ? new Date(item.createdAt).toLocaleDateString("pt-BR")
                  : item.criadoEm}
              </Card.Footer>
            </Card>
          ))}
        </Card.Body>
        <Card.Footer className="p-0 text-center">
          <Button
            onClick={handleVerMaisClick}
            variant="link"
            className="fw-bold text-decoration-none"
            style={{ color: view === "eventos" ? "#238ee6" : "#198754" }}
          >
            Ver mais
          </Button>
        </Card.Footer>
      </Card>
    );
  };

  // Se o usuário for "usuario_cidade = Sim" ou "advogado_externo = Sim", mostrar saudação
  if (!podeVisualizarDashboard) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          height: "80vh",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h1 style={{ fontSize: "2.4rem", color: "#333" }}>
          Olá, {nomeUsuario}!
        </h1>
        <h2 style={{ fontSize: "1.8rem", color: "#555", marginTop: "0.5rem" }}>
          Bem-vindo ao Eureka
        </h2>
      </div>
    );
  }

  // Caso contrário, dashboard normal
  /* //SSI 0112 - PFERRI - Ajuste no layout da home - 14/02/2025 */
  return (
    <>
      <Row className="mb-6 gy-2">
        <Col xs={12} md={6}>
          <div className="mb-4">
            <h2 className="mb-3 text-body-emphasis">Eureka</h2>
            <h5 className="text-body-tertiary fw-semibold">
              Sistema administrativo de condomínios
            </h5>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "16px",
              boxSizing: "border-box",
            }}
          >
            {stats.map((stat, index) => (
              <div
                key={stat.title}
                style={{
                  flex: "1 1 auto",
                  maxWidth: "calc(33.33% - 16px)",
                  minWidth: "216px",
                  textAlign: "center",
                  boxSizing: "border-box",
                }}
              >
                <Stat stat={stat} color={colors[index % colors.length]} />
              </div>
            ))}
          </div>
          <hr />
          <div className="mt-4 text-body">{renderCards()}</div>
        </Col>
        <Col xs={12} md={6}>
          <div className="mb-0 text-body">
            <h4 className="fw-semibold text-body-tertiary">
              Crescimento de Condomínios
            </h4>
            <p className="mb-0">
              O gráfico abaixo apresenta o crescimento do total de condomínios
              ao decorrer dos meses.
            </p>
          </div>
          <GraficoCondominios />
        </Col>
      </Row>

      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 pb-3 border-y">
        <Row>
          <Col xs={12} xl={7} xxl={6}>
            <CondosPorCidade />
          </Col>
          <Col xs={12} xl={5} xxl={6}>
            <GraficoAssembleias />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardProject;
