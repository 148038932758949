// LCORREA - SSI 0020 - 04/10/2024 - Criação e Gestão dos planos de contas
// GCOLLE - SSI 0037 - 28/10/2024 - Refazendo para modelo Phoenix e implementando lógica envio e download Fatura para Usina
//SSI 0113 - PFERRI - Ajuste na permissão do lider de squad para vizualizar o financeiro - 14/02/2025
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon, UilFilesLandscapesAlt } from "@iconscout/react-unicons";

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: "font-awesome" | "feather" | "unicons";
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  requiredRoles?: string[];
  requiredPermissions?: {
    funcao?: string;
    area?: string;
    usuario_cidade?: string; // "Sim" ou "Não"
    advogado_externo?: string; // "Sim" ou "Não"
    isLiderSquad?: boolean;
  }[];
  //SSS 0092 - MATHEUS BOTELHO - 15/01/2025
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: "pages",
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: "Assembleias",
        icon: "calendar",
        active: true,
        flat: true,
        path: "/pages/assembleias",
        pathName: "assembleias",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
          { funcao: "Gestor", area: "Assembleias" },
          { funcao: "Agente", area: "Assembleias" },
        ],
        pages: [
          {
            name: "Agenda",
            path: "/pages/assembleias/agenda-assembleia",
            pathName: "agenda-assembleia",
            topNavIcon: "shopping-cart",
            active: true,
          },
        ],
      },
      {
        name: "Cadastro",
        icon: "clipboard",
        active: true,
        flat: true,
        path: "/pages/cadastro",
        pathName: "cadastro",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
          { funcao: "Gestor", area: "Cadastro" },
          { funcao: "Agente", area: "Cadastro" },
        ],
        pages: [
          // {
          //   name: "Alterar Titularidade",
          //   path: "/pages/cadastro/alterar-titularidade",
          //   pathName: "alterar-titularidade",
          //   topNavIcon: "shopping-cart",
          //   active: true,
          // },
          {
            name: "Alterar Titularidade",
            path: "/pages/cadastro/alterar-titularidade",
            pathName: "alterar-titularidade",
            topNavIcon: "shopping-cart",
            active: true,
          },
        ],
      },
      {
        name: "Financeiro",
        icon: "dollar-sign",
        active: true,
        path: "/pages/financeiro",
        pathName: "financeiro",
        //SSI 0092 - MATHEUS BOTELHO - 15/01/2025 - INICIO
        requiredPermissions: [
          { funcao: "admin" }, // Admins podem acessar
          { funcao: "Gestor", area: "Financeiro" }, // Gestores do Financeiro podem acessar
          { funcao: "Agente", area: "Financeiro" }, // Agentes do Financeiro podem acessar
          { isLiderSquad: true }, //SSI 0113 - PFERRI - Ajuste na permissão do lider de squad - 14/02/2025
        ],
        //SSI 0092 - MATHEUS BOTELHO - 15/01/2025 - FIM
        pages: [
          {
            name: "Rateio",
            path: "/pages/financeiro/rateio",
            pathName: "rateio",
            topNavIcon: "share-2",
            active: true,
          },
          {
            name: "Fechamento Mensal",
            path: "/pages/financeiro/fechamento-mensal",
            pathName: "fechamento-mensal",
            topNavIcon: "calendar",
            active: true,
          },
        ],
      },
      {
        name: "Geral",
        icon: "grid",
        active: true,
        path: "/pages/geral",
        pathName: "geral",
        pages: [
          {
            name: "Agenda",
            topNavIcon: "calendar",
            active: true,
            path: "/pages/geral/agenda",
            pathName: "agenda",
            requiredPermissions: [
              //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
              {
                funcao: "admin",
                advogado_externo: "Não",
              },
              {
                funcao: "Gestor",
                advogado_externo: "Não",
              },
              {
                funcao: "Agente",
                advogado_externo: "Não",
              },
            ],
            pages: [
              // SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - INICIO
              {
                name: "Ver Agenda",
                path: "/pages/geral/agenda/ver-agenda",
                pathName: "ver-agenda",
                topNavIcon: "eye",
                active: true,
              },
              // SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - FIM
            ],
          },
          {
            name: "Carteiras",
            icon: "wallet",
            active: true,
            path: "/pages/geral/carteiras",
            pathName: "carteiras",
            pages: [
              {
                name: "Editar Carteira",
                path: "/pages/geral/carteiras/editar-carteira",
                requiredPermissions: [
                  //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                  { funcao: "admin" },
                  { funcao: "Gestor" },
                ],
                pathName: "editar-carteira",
                topNavIcon: "edit",
                active: true,
              },
              {
                name: "Consultar Carteira",
                path: "/pages/geral/carteiras/consultar-carteira",
                pathName: "consultar-carteira",
                topNavIcon: "search",
                active: true,
              },
              {
                name: "Redirecionar Carteira",
                path: "/pages/geral/carteiras/redirecionar-carteira",
                requiredPermissions: [
                  //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                  { funcao: "admin" },
                  { funcao: "Gestor" },
                ],
                pathName: "redirecionar-carteira",
                topNavIcon: "arrow-right",
                active: true,
              },
              {
                name: "Transferência de Carteira",
                path: "/pages/geral/carteiras/transferencia-de-carteira",
                requiredPermissions: [
                  //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                  { funcao: "admin" },
                  { funcao: "Gestor" },
                ],
                pathName: "transferencia-de-carteira",
                topNavIcon: "transfer",
                active: true,
              },
              {
                name: "Carteira do Usuário",
                path: "/pages/geral/carteiras/carteira-do-usuario",
                requiredPermissions: [
                  //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                  { funcao: "admin" },
                  { funcao: "Gestor" },
                ],
                pathName: "carteira-do-usuario",
                topNavIcon: "user",
                active: true,
              },
              {
                name: "Minha Carteira",
                path: "/pages/geral/carteiras/minha-carteira",
                pathName: "minha-carteira",
                topNavIcon: "home",
                active: true,
              },
              {
                name: "Editar Usuários Cidade",
                path: "/pages/geral/carteiras/editar-usuarios-cidade",
                requiredPermissions: [
                  //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                  { funcao: "admin" },
                  { funcao: "Gestor" },
                ],
                pathName: "editar-usuarios-cidade",
                topNavIcon: "users",
                active: true,
              },
            ],
          },
        ],
      },
      {
        name: "Indicadores",
        icon: "bar-chart-2",
        active: true,
        path: "/pages/indicadores",
        pathName: "indicadores",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
        ],
        pages: [
          // {
          //   name: "ERRADO",
          //   path: "/pages/indicadores/inadiplencias",
          //   pathName: "inadiplencias",
          //   topNavIcon: "alert-triangle",
          //   active: true,
          // },
          // SSI 0089 - MATHEUS BOTELHO - 14/01/2025 - INICIO
          {
            name: "Inadimplências",
            path: "/pages/indicadores/inadimplencias",
            pathName: "inadimplencias",
            topNavIcon: "alert-triangle",
            active: true,
          },
          // SSI 0089 - MATHEUS BOTELHO - 14/01/2025 - FIM

          //SSI 0088 - MATHEUS BOTELHO - 13/01/2025 - INICIO
          {
            name: "Lançamentos Financeiro",
            path: "/pages/indicadores/lancamentos-financeiro",
            pathName: "lancamentos-financeiro",
            topNavIcon: "book",
            active: true,
          },
          //SSI 0088 - MATHEUS BOTELHO - 13/01/2025 - FIM
        ],
      },
      {
        name: "Informações",
        icon: "info",
        active: true,
        path: "/pages/informacoes",
        pathName: "informacoes",
        requiredPermissions: [
          // Mantém permissão apenas para usuários
          { funcao: "admin" },
          { funcao: "Gestor" },
          { isLiderSquad: true },
        ],
        pages: [
          {
            name: "Usuários",
            topNavIcon: "users",
            active: true,
            path: "/pages/informacoes/usuarios",
            pathName: "usuarios",
            requiredPermissions: [
              // Mantém permissão apenas para usuários
              { funcao: "admin" },
              { funcao: "Gestor" },
            ],
            pages: [
              {
                name: "Geral",
                path: "/pages/informacoes/usuarios/geral",
                pathName: "geral",
                topNavIcon: "edit",
                active: true,
              },
              {
                name: "Ausência",
                path: "/pages/informacoes/usuarios/ausencia",
                pathName: "ausencia",
                topNavIcon: "user-minus",
                active: true,
              },
              {
                name: "Inativar Usuário",
                path: "/pages/informacoes/usuarios/inativar-usuario",
                pathName: "inativar-usuario",
                topNavIcon: "user-x",
                active: true,
              },
            ],
          },
          {
            name: "Squads",
            topNavIcon: "squads",
            active: true,
            path: "/pages/informacoes/squads",
            pathName: "squads",
            requiredPermissions: [
              // Apenas administradores e líderes de squad podem acessar Squads
              //SSI 0108 - GCOLLE - PERMISSÃO LIDER_SQUAD - 03/02/25
              //SSI 0122 - GCOLLE - Permissão página Routes isLiderSquad || Admin || Gestor - 24/02/25
              { funcao: "admin" },
              { funcao: "Gestor" },
              { isLiderSquad: true },
            ],
            pages: [
              {
                name: "Geral",
                path: "/pages/informacoes/squads/geral",
                pathName: "geral",
                topNavIcon: "edit",
                active: true,
              },
            ],
          },
        ],
      },
      {
        name: "Jurídico",
        icon: "briefcase",
        active: true,
        path: "/pages/juridico",
        pathName: "juridico",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
          { funcao: "Gestor", area: "Jurídico" },
          { funcao: "Gestor", area: "Cobrança" },
          { funcao: "Agente", area: "Jurídico" },
          { funcao: "Agente", area: "Cobrança" },
        ],
        pages: [
          {
            name: "inadimplencias",
            path: "/pages/juridico/inadimplencias",
            pathName: "inadimplencias",
            topNavIcon: "alert-circle",
            active: true,
          },
        ],
      },
      {
        name: "Notificações",
        icon: "bell",
        active: true,
        path: "/pages/notificacoes",
        pathName: "notificacoes",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
          { funcao: "Gestor" },
        ],
        pages: [
          {
            name: "Criar Notificação",
            path: "/pages/notificacoes/criar-notificacao",
            pathName: "criar-notificacao",
            topNavIcon: "bell",
            active: true,
          },
        ],
      },
      {
        name: "RH",
        icon: "users",
        active: true,
        path: "/pages/rh",
        pathName: "rh",
        requiredPermissions: [
          //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
          { funcao: "admin" },
          { funcao: "Gestor", area: "RH" },
          { funcao: "Agente", area: "RH" },
        ],
        pages: [
          //SSI 0091 - MATHEUS BOTELHO - 15/01/2025 - INICIO
          {
            name: "Importação Salários",
            path: "/pages/rh/importacao-salarios",
            pathName: "importacao-salarios",
            topNavIcon: "percent",
            active: true,
          },
        ],
        //SSI 0091 - MATHEUS BOTELHO - 15/01/2025 - FIM
      },
      {
        name: "Atualizações",
        icon: "users",
        active: true,
        path: "/pages/atualizacoes",
        pathName: "atualizacoes",
        pages: [
          {
            name: "Atualizações Eureka",
            path: "/pages/atualizacoes/atualizacoes-eureka",
            pathName: "atualizacoes-eureka",
            topNavIcon: "percent",
            active: true,
          },
          {
            name: "Listar Eventos",
            path: "/pages/atualizacoes/listar-eventos",
            pathName: "listar-eventos",
            topNavIcon: "percent",
            active: true,
          },
          {
            name: "Criar Eventos",
            path: "/pages/atualizacoes/criar-eventos",
            requiredPermissions: [
              //SSI 0100 - MATHEUS BOTELHO - 23/01/2025
              { funcao: "admin" },
              { funcao: "Gestor" },
            ],
            pathName: "criar-eventos",
            topNavIcon: "percent",
            active: true,
          },
        ],
      },
    ],
  },
];
